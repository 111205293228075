import { Loader } from "@progress/kendo-react-indicators";

const LoaderComponent = ({description}) => {

    return (
        <div className="loader-page">
            <div style={{ margin: "auto" }}>
                <Loader size="large" type={"converging-spinner"} size="large" />
                <p>{description}</p>
            </div>
            
        </div>
        
        );
}

export default LoaderComponent;