import {
    LOAD_USERS, LOAD_USERS_SUCCESS,
    DELETE_USER, DELETE_USER_SUCCESS,
    UPDATE_USER, UPDATE_USER_SUCCESS,
    EDIT_USER_ENTER, EDIT_USER, CANCEL_EDIT_USER

} from "./actions";

// LOAD USERS
export const loadUsers = () => ({
    type: LOAD_USERS
})
export const loadUsersSuccess = (data) => ({
    type: LOAD_USERS_SUCCESS,
    payload: data
})

// UPDATE USER
export const updateUser = (data) => ({
    type: UPDATE_USER,
    payload: data
})
export const updateUserSuccess = (data) => ({
    type: UPDATE_USER_SUCCESS,
    payload: data
})

// DELETE USER
export const deleteUser = (data) => ({
    type: DELETE_USER,
    payload: data
})
export const deleteUserSuccess = (data) => ({
    type: DELETE_USER_SUCCESS,
    payload: data
})

// EDIT USER
export const editUserEnter = (data) => ({
    type: EDIT_USER_ENTER,
    payload: data
})
export const cancelEditUser = (data) => ({
    type: CANCEL_EDIT_USER,
    payload: data
})
export const editUser = (id, field, value) => ({
    type: EDIT_USER,
    payload: {
        id,
        field,
        value
    }
})