import { isMobile } from 'react-device-detect';

import DasboardD from "./DashboardD";
import DashboardM from "./DashboardM";


const Dashboard = () => {
    return (
        isMobile ? <DashboardM /> : <DasboardD />
    );
}

export default Dashboard;