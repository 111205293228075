import { useState } from "react";

import { useLocalization } from "@progress/kendo-react-intl";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";

import { PayoutTypeCell, DateTimeCell, NumberCell, DateTimeCellType } from "../../../views/DataGridCells";

import { TIME, TYPE, AMOUNT, ACCOUNT } from "../../../../infrastructure/localizationKeys";

const DateCell = (props) => {
    return <DateTimeCell {...props} type={DateTimeCellType.DATE} />
}

const PortfolioPayouts = (props) => {
    const localization = useLocalization();
    const [sort, setSort] = useState([]);
    const sortChange = (event) => setSort(event.sort);

    return (
        <Grid
            data={orderBy(props.data, sort)}
            onSortChange={sortChange}
            sort={sort}
            sortable={true}
            style={{ height: "300px" }}
        >
            <Column field="DateTime" title={localization.toLanguageString(TIME)} cell={DateCell} />
            <Column field="Number" title={localization.toLanguageString(ACCOUNT)} />
            <Column field="Type" title={localization.toLanguageString(TYPE)} cell={PayoutTypeCell} />
            <Column field="Value" title={localization.toLanguageString(AMOUNT)} cell={NumberCell} />
        </Grid>
    )
}

export default PortfolioPayouts;