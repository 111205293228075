import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useLocalization } from "@progress/kendo-react-intl";

import { correlationLoad } from "../../../store/reducers/correlationReducer/creators";
import CorrelationTable from "./CorrelationTable";
import { LOADER } from "../../../infrastructure/localizationKeys";
import LoaderComponent from "../../views/LoaderComponent";

const CorrelationD = () => {
    const dispatch = useDispatch();
    const localization = useLocalization();

    const loaded = useSelector(state => state.correlation.loaded);
    const loading = useSelector(state => state.correlation.loading);
    const transparentLoader = useSelector(state => state.correlation.transparentLoader);

    useEffect(() => {
        if (!loaded && !loading)
            dispatch(correlationLoad());
    });

    return (loading
        ? <LoaderComponent description={localization.toLanguageString(LOADER)} />
        : (<>
            {transparentLoader ? <div className="loader-over"> <LoaderComponent description={localization.toLanguageString(LOADER)} /></div> : null}
            < CorrelationTable />
        </>
        )
    );
}

export default CorrelationD;