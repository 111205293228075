import {
    PORTFOLIO_LOAD_DATA, PORTFOLIO_LOAD_DATA_SUCCESS, PORTFOLIO_LOAD_DATA_ERROR,
    PORTFOLIO_UPDATE_DATA,
    PORTFOLIO_SET_DATA
} from "./actions";

import { LOGOUT, UPDATE, CLEAR_ALL, UPDATE_ALL_DATA } from "../generalReducer/generalActions";

const initial = {
    loaded: false,
    loading: false,
    data: [],
    portfolioProperties: [],
    cashBalances: [],
    update: {}
}

function portfolioReducer(state = initial, action) {
    switch (action.type) {
        // LOAD DATA
        case PORTFOLIO_LOAD_DATA:
            return {
                ...state,
                loading: true,
            }
        case PORTFOLIO_LOAD_DATA_SUCCESS:
            return {
                ...state,
                loaded: true,
                loading: false,
                ...action.payload
            }
        case PORTFOLIO_LOAD_DATA_ERROR:
            return {
                ...state,
                loaded: true,
                loading: false,
            }

        // SET_DATA
        case PORTFOLIO_SET_DATA:
            return {
                ...state,
                data: [...state.data]
            }

        // UPDATE DATA
        case UPDATE:
            const noUpdate = Object.keys(state.update).length === 0;

            if (noUpdate)
                return state;

            return {
                ...state,
                data: state.data.map(g => {
                    return {
                        ...g,
                        positions: g.positions.map(p => {
                            return {
                                ...p,
                                ...state.update[p.instrument.Symbol]
                            }
                        })
                    }
                }),
                update: {}
            }

        case PORTFOLIO_UPDATE_DATA:
            let { symbol, propertyName, value } = action.payload;
            propertyName = propertyName[0].toLowerCase() + propertyName.slice(1);

            return {
                ...state,
                update: {
                    ...state.update,
                    [symbol]: {
                        ...state.update[symbol],
                        [propertyName]: value,
                    }
                }
            }
        case UPDATE_ALL_DATA:
            return {
                ...state,
                loading: false,
                loaded: false
            }
        case CLEAR_ALL:
            return initial;
        case LOGOUT:
            return initial;
        default:
            return state;
    }
}

export default portfolioReducer;