import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import UsageGrid from "./UsageGrid";
import FuncUsageGrid from "./FuncUsageGrid";

import { loadUsage } from "../../../store/reducers/usageReducer/creators";

import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";

function Usages() {

    const loaded = useSelector(state => state.usage.loaded)
    const loading = useSelector(state => state.usage.loading)
    const dispatch = useDispatch();

    useEffect(() => {
        if (!loaded && !loading)
            dispatch(loadUsage());
    });

    return (
        <>
            <GridLayout
                align={{ vertical: "stretch" }}
                cols={[{ width: "50%" }, { width: "50%" }]}
                gap={{ rows: 1, cols: 2 }}>

                <GridLayoutItem row={1} col={1}>
                    <UsageGrid />
                </GridLayoutItem>


                <GridLayoutItem row={1} col={2}>
                    <FuncUsageGrid />
                </GridLayoutItem>

            </GridLayout >
            
        </>
    );
}

export default Usages;