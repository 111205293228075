import { CHANGE_EXPAND_MENU_SAGA } from "./generalActions";

const initial = {
    expandMenu: true
}

const generalReducer = (state = initial, action) => {
    switch (action.type) {
        case CHANGE_EXPAND_MENU_SAGA:
            return {
                ...state,
                expandMenu: !state.expandMenu,
            }
        default:
            return state;
    }
}

export default generalReducer;
