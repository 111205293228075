import { QRCode } from "@progress/kendo-react-barcodes";
import { useSelector } from "react-redux";

const BindDevice = () => {
    const token = useSelector(state => state.login.token);

    const currentUrl = window.location.host;

    const valueQrCode = "TFIQrCode: " + currentUrl + " | " + token;

    const qrcodeStyles = {
        width: "450px",
        "min-width": "100px",
        "aspect-ratio": "1/1",
    };

    const cardStyles = {
        width: "500px",
    };

    return (
        <div>
            <div className="k-card k-text-center" style={cardStyles}>
                <div className="k-card-header">
                    <div className="k-card-title">Отсканируйте QR-код в мобильном приложении</div>
                </div>
                <div className="k-card-body">
                    <QRCode style={qrcodeStyles} value={valueQrCode} />
                </div>
            </div>           
        </div>
    );
}

export default BindDevice;