import { useLocalization } from "@progress/kendo-react-intl";

import {TOTAL, REALIZED_PL, UNREALIZED_PL} from "../../../infrastructure/localizationKeys";

const TotalLabel = ({ data }) => {
    if (data.length > 0) {
        const localization = useLocalization();

        const numericFormat =
            new Intl.NumberFormat("en", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 });

        let sumRealizedPl = numericFormat.format(data.reduce((n, { realizedPl }) => n + realizedPl, 0));
        let sumIUnrealizedPl = numericFormat.format(data.reduce((n, { unrealizedPl }) => n + unrealizedPl, 0));

        return (
            <pre className="total-label">
            {localization.toLanguageString(TOTAL)}: {data.length}    {localization.toLanguageString(REALIZED_PL)}: {
                sumRealizedPl}    {localization.toLanguageString(UNREALIZED_PL)}: {sumIUnrealizedPl}
        </pre>
        );
    }
    else {
        return (
            <pre className="total-label">
                {localization.toLanguageString(TOTAL)}: {0}
            </pre>);
    }
}

export default TotalLabel;
