import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GridLayout, GridLayoutItem, Splitter } from "@progress/kendo-react-layout";
import { useLocalization } from "@progress/kendo-react-intl";

import { loadEquity, equityPropertiesUpdate } from "../../../store/reducers/equityReducer/creators"

import {LOADER} from "../../../infrastructure/localizationKeys";
import ObjectEditor from "../../views/ObjectEditor";
import Chart from "./Chart";
import FooterTabs from "./FooterTabs";
import LoaderComponent from "../../views/LoaderComponent";

const Equity = () => {

    const dispatch = useDispatch();
    const localization = useLocalization();
    const loading = useSelector(state => state.equity.loading);
    const loaded = useSelector(state => state.equity.loaded);
    const error = useSelector(state => state.equity.error);
    const properties = useSelector(state => state.equity.properties);

    const loader = useSelector(state => state.equity.loader);

    useEffect(() => {
        if (!loading && !loaded) {
            dispatch(loadEquity());
        }
    })

    const updateHandler = (result) => {
        dispatch(equityPropertiesUpdate(result));
    }

    const [panes, setPanes] = useState([{ size: "420px" }, {}]);

    const [height, setHeight] = useState(window.innerHeight);
    

    let heightPanel = (height - panes[0].size.replace("px", '') -220) + "px";
    
    const onChange = (event) => {
        setPanes(event.newState);
    };

    if (error)
        return null;

    const resizeWindow = () => {
        setHeight(window.innerHeight);
    }

    return loading || !loaded ?
        (<LoaderComponent description={localization.toLanguageString(LOADER)} />) :
        (<>
            {window.addEventListener('resize', resizeWindow, false)}
            {loader ? <div className="loader-over"> <LoaderComponent description={localization.toLanguageString(LOADER)} /></div> : null}
            <GridLayout align={{
                vertical: "stretch",
            }}
                
                gap={{
                    rows: 3,
                    cols: 1,
                }}>
                <GridLayoutItem row={1} col={1}>
                    <ObjectEditor data={properties} onUpdate={updateHandler} orientation="horizontal" />
                </GridLayoutItem>
                <GridLayoutItem row={2} col={1}>
                    <Splitter panes={panes} onChange={onChange} orientation={"vertical"}>
                        <Chart />
                        <FooterTabs height={heightPanel}/>
                    </Splitter>
                </GridLayoutItem>
            </GridLayout>
        </>);
}

export default Equity;