import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useLocalization } from "@progress/kendo-react-intl";

import { restorePostionsLoad } from "../../../store/reducers/restorePositionsReducer/creators";
import { LOADER } from "../../../infrastructure/localizationKeys";
import LoaderComponent from "../../views/LoaderComponent";
import RestorePositionsTable from "./RestorePositionsTable";

const RestorePositionsD = () => {

    const dispatch = useDispatch();
    const localization = useLocalization();

    const loaded = useSelector(state => state.restorePositions.loaded);
    const loading = useSelector(state => state.restorePositions.loading);

    useEffect(() => {
        if (!loaded && !loading)
            dispatch(restorePostionsLoad());
    });

    return (
        loading
            ? <LoaderComponent description={localization.toLanguageString(LOADER)} />
            : <RestorePositionsTable />
    );
}

export default RestorePositionsD;