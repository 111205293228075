import { useSelector } from "react-redux";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useLocalization } from "@progress/kendo-react-intl";

import PieChart from "./PieChart";
import {
    DASHBOARD_PIE_CHART_HEADER_ASSETS,
    DASHBOARD_PIE_CHART_HEADER_BROKERS,
    DASHBOARD_PIE_CHART_HEADER_COMPANIES,
    DASHBOARD_PIE_CHART_HEADER_CURRENCIES,
    DASHBOARD_PIE_CHART_HEADER_INSTRUMENTS,
    DASHBOARD_PIE_CHART_HEADER_SECTORS
} from "../../../../infrastructure/localizationKeys";

const PieCharts = () => {
    const localization = useLocalization();

    const pies = useSelector(state => state.dashboard.pies);
    
    return (
        <>
            <GridLayout align={{
                vertical: "stretch",
            }}
                
                cols={[
                    {
                        width: "33.3%",
                    },
                    {
                        width: "33.3%",
                    },
                    {
                        width: "33.3%",
                    },
                ]}
                        gap={{
                    rows: 2,
                    cols: 3,
                }}>
                <GridLayoutItem row={1} col={1}>
                    <PieChart name={localization.toLanguageString(DASHBOARD_PIE_CHART_HEADER_ASSETS)} data={pies?.assetsPie} />
                </GridLayoutItem>
                <GridLayoutItem row={1} col={2}>
                    <PieChart name={localization.toLanguageString(DASHBOARD_PIE_CHART_HEADER_SECTORS)} data={pies?.sectorsPie} />
                </GridLayoutItem>
                <GridLayoutItem row={1} col={3}>
                    <PieChart name={localization.toLanguageString(DASHBOARD_PIE_CHART_HEADER_INSTRUMENTS)} data={pies?.instrumentsPie} />
                </GridLayoutItem>
                <GridLayoutItem row={2} col={1}>
                    <PieChart name={localization.toLanguageString(DASHBOARD_PIE_CHART_HEADER_COMPANIES)} data={pies?.companiesPie} />
                </GridLayoutItem>
                <GridLayoutItem row={2} col={2}>
                    <PieChart name={localization.toLanguageString(DASHBOARD_PIE_CHART_HEADER_CURRENCIES)} data={pies?.сurrenciesPie} />
                </GridLayoutItem>
                <GridLayoutItem row={2} col={3}>
                    <PieChart name={localization.toLanguageString(DASHBOARD_PIE_CHART_HEADER_BROKERS)} data={pies?.brokersPie} />
                </GridLayoutItem>
            </GridLayout>
        </>
    );
}


export default PieCharts;