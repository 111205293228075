import { isMobile } from 'react-device-detect';

import RestorePositionsD from "./RestorePositionsD";
import RestorePositionsM from "./RestorePositionsM";

const RestorePositions= () => {
    return (
        isMobile ? <RestorePositionsM /> : <RestorePositionsD />
    );
}

export default RestorePositions;