import { useDispatch } from "react-redux";
import { DEBUG_ERROR } from "../../../store/sagas/debugSaga";

const SIMPLE_ERROR = "GET";
const TASK_ERROR = "POST";

const Debug = () => {
    const dispatch = useDispatch();

    const errorHandler = (type) => () => dispatch({
        type: DEBUG_ERROR,
        payload: type
    })

    return (
        <div>
            <button onClick={errorHandler(SIMPLE_ERROR)}>Simple error</button>
            <button onClick={errorHandler(TASK_ERROR)} className="ms-3">Task error</button>
        </div>
    );
}

export default Debug;