import {
    PROFIT_FIXER_LOAD_DATA,
    PROFIT_FIXER_LOAD_DATA_SUCCESS,
    PROFIT_FIXER_LOAD_DATA_ERROR,
    PROFIT_FIXER_PROPERTIES_UPDATE,
    PROFIT_FIXER_UPDATE_COLLECTION_ADD,
    PROFIT_FIXER_UPDATE_COLLECTION_REMOVE,
    PROFIT_FIXER_UPDATE_OPEN_EXECUTION
} from "./actions";
import { LOGOUT,CLEAR_ALL, UPDATE_ALL_DATA, UPDATE } from "../generalReducer/generalActions";

const initial = {
    loaded: false,
    loading: false,
    data: [],
    properties: [],
    update: []
}

const profitFixerReducer = (state = initial, action) => {
    switch (action.type) {
        case PROFIT_FIXER_LOAD_DATA:
            return {
                ...state,
                loading: true
            }
        case PROFIT_FIXER_LOAD_DATA_SUCCESS:
            return {
                ...state,
                loaded: true,
                loading: false,
                ...action.payload
            }
        case PROFIT_FIXER_PROPERTIES_UPDATE:
            
            return {
                ...state,
                ...action.payload
            }
        case PROFIT_FIXER_LOAD_DATA_ERROR:
            return {
                ...state,
                loaded: true,
                loading: false,
            }
        case UPDATE_ALL_DATA:
            return {
                ...state,
                loading: false,
                loaded: false
            }
        case PROFIT_FIXER_UPDATE_COLLECTION_ADD:
            let find = state.data.find(p => p.account.Number === action.payload.account.Number &&
                    p.instrument.Name === action.payload.instrument.Name &&
                    p.tradeAction === action.payload.tradeAction &&
                    p.quantity === action.payload.quantity &&
                    p.priceDecorator === action.payload.priceDecorator)
            if (find === undefined) {
                return {
                    ...state,
                    data: [...state.data, action.payload]
                }
            } else {
                return { ...state }
            }
        case PROFIT_FIXER_UPDATE_COLLECTION_REMOVE:
            const findOld = state.data.find(p => p.account.Number === action.payload.account.Number &&
                p.instrument.Name === action.payload.instrument.Name &&
                p.tradeAction === action.payload.tradeAction &&
                p.quantity === action.payload.quantity &&
                p.priceDecorator === action.payload.priceDecorator);
            return{
                ...state,
                data: state.data.filter(p => p != findOld)
            }
        case PROFIT_FIXER_UPDATE_OPEN_EXECUTION:
            return {
                ...state,
                update: [...state.update, action.payload]
            }
        case UPDATE:
            const noUpdate = Object.keys(state.update).length === 0;
            if (noUpdate)
                return state;
            return {
                ...state,
                data: state.data.map(p => {
                    const find = state.update.find(x => x.account.Number === p.account.Number &&
                        x.instrument.Name === p.instrument.Name &&
                        x.tradeAction === p.tradeAction &&
                        x.quantity === p.quantity &&
                        x.priceDecorator === p.priceDecorator);
                    if (find != undefined) {
                        return find;
                    } else {
                        return p;
                    }
                }),
                update: []
            }
        case CLEAR_ALL:
            return initial;
        case LOGOUT:
            return initial;
        default:
            return state;
    }
}

export default profitFixerReducer;