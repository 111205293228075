export const IDEAS_LOAD_DATA = "IDEAS_LOAD_DATA";
export const IDEAS_LOAD_DATA_SUCCESS = "IDEAS_LOAD_DATA_SUCCESS";
export const IDEAS_LOAD_DATA_ERROR = "IDEAS_LOAD_DATA_ERROR";

export const ADD_IDEA = "ADD_IDEA";
export const ADD_IDEA_SUCCESS = "ADD_SUCCESS";

export const DEACTIVATE_IDEA = "DEACTIVATE_IDEA";
export const DEACTIVATE_IDEA_SUCCESS = "DEACTIVATE_IDEA_SUCCESS";

export const IDEA_UPDATE = "IDEA_UPDATE";
export const IDEA_UPDATE_SUCCESS = "IDEA_UPDATE_SUCCESS";

export const IDEA_ADD_NOTE = "IDEA_ADD_NOTE";
export const IDEA_ADD_NOTE_SUCCESS = "IDEA_ADD_NOTE_SUCCESS";

export const UPDATE_NOTE = "UPDATE_NOTE";
export const UPDATE_NOTE_SUCCESS = "UPDATE_NOTE_SUCCESS";

export const CHANGE_SYMBOL = "CHANGE_SYMBOL";
export const CHANGE_NOTE = "CHANGE_NOTE";

export const SELECT_IDEA = "SELECT_IDEA";
export const SELECT_NOTE = "SELECT_NOTE";