import {
    PROFIT_FIXER_LOAD_DATA,
    PROFIT_FIXER_LOAD_DATA_SUCCESS,
    PROFIT_FIXER_PROPERTIES_UPDATE,
    PROFIT_FIXER_LOAD_DATA_ERROR,
    PROFIT_FIXER_UPDATE_COLLECTION_ADD,
    PROFIT_FIXER_UPDATE_COLLECTION_REMOVE,
    PROFIT_FIXER_UPDATE_OPEN_EXECUTION
} from "./actions";

export const profitFixerLoad = () => ({
    type: PROFIT_FIXER_LOAD_DATA
});

export const profitFixerLoadSuccess = (data) => ({
    type: PROFIT_FIXER_LOAD_DATA_SUCCESS,
    payload: data
});

export const profitFixerPropertiesUpdate = (data) => ({
    type: PROFIT_FIXER_PROPERTIES_UPDATE,
    payload: data
});

export const profitFixerLoadError = () => ({
    type: PROFIT_FIXER_LOAD_DATA_ERROR
});

export const profitFixerUpdateCollectionAdd = (data) => ({
    type: PROFIT_FIXER_UPDATE_COLLECTION_ADD,
    payload: data
});

export const profitFixerUpdateCollectionRemove = (data) => ({
    type: PROFIT_FIXER_UPDATE_COLLECTION_REMOVE,
    payload: data
})

export const profitFixerOpenExecutionUpdate = (data) => ({
    type: PROFIT_FIXER_UPDATE_OPEN_EXECUTION,
    payload: data
})