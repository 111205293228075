const OtherInstruments = () => {
    return <div className="help-page">
        <div className="text-help">
            <p style={{ paddingTop: "10px" }}>Большинство инстументов связано с торговыми операциями и требует подключние к брокеру.<br/>
                В целях безопасности и приватности, мы не осуществляем подключение к вашим брокерским счетам с нашего сервера.<br />
                <br />
                В десктоп версии доступны следующие дополнительные инстументы:<br />
                <ul>
                    <li>Подбор облигаций</li>
                    <li>Фиксация прибыли</li>
                    <li>Восстановление позиций</li>
                    <li>Кореляция</li>
                    <li>Карта рынка</li>
                    <li>Алгоритмы (набор позиции и фиксация прибыли по заданным правилам)</li>
                </ul>
                Установить десктоп версию для Windows можно перейдя по следующией <a href="https://download.investortools.ru/InvestorTools.application">ссылке</a>.

            </p>
        </div>
    </div>
}

export default OtherInstruments;