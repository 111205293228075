import { useDispatch, useSelector } from "react-redux";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useLocalization } from "@progress/kendo-react-intl";
import { useState } from "react";
import { orderBy } from "@progress/kendo-data-query";
import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from "@progress/kendo-react-dialogs";

import { profitFixerPropertiesUpdate } from "../../../store/reducers/profitFixerReducer/creators"
import ObjectEditor from "../../views/ObjectEditor";
import { TradeActionCell, QuantityCell, NumberCell, PercentCell, RightAlignCell } from "../../views/DataGridCells";

import {
    NAME, QUANTITY, LAST_PRICE, DAY_PRICE_CHANGE, UNREALIZED_PL, CLOSE_BUTTON_CONTENT,
    PORTFOLIO_TOTAL_PL_PERCENT, ACCOUNT, ACTION, PRICE, TABLE_HEADER_ACTIONS, INFO_CLOSE_POSITION
    }
    from "../../../infrastructure/localizationKeys";

const PercentColorCell = (props) => {
    return <PercentCell {...props} colored={true} />
}

const OpenExecutionsTable = () => {
    const dispatch = useDispatch();
    const localization = useLocalization();

    const data = useSelector(state => state.profitFixer.data);
    const properties = useSelector(state => state.profitFixer.properties);

    const [visibleModalInfo, setVisibleModalInfo] = useState(false);

    const handleUpdate = (properties) => {
        dispatch(profitFixerPropertiesUpdate(properties))
    }
    const initialSort = [
        {
            field: "plPercent",
            dir: "desc",
        },
    ];
    const [sort, setSort] = useState(initialSort);
    const sortChange = (event) => setSort(event.sort);

    const styleBtn = {
        height: "35px",
        width: "inherit"
    }

    const styleCell = {
        display: "inline-block",
        width: "-webkit-fill-available"
    }

    const toggleDialogDelete = () => {
        setVisibleModalInfo(!visibleModalInfo);
    };

    const viewModalInfo = () => {
        setVisibleModalInfo(true);
    }

    const DialogModalInfo = () => {
        return (
            visibleModalInfo &&
                <Dialog title="" modal={true} onClose={toggleDialogDelete} resizable={true} width={550}>
                <h5>{localization.toLanguageString(INFO_CLOSE_POSITION)}</h5>
                    
                <Button onClick={toggleDialogDelete} className="btn-dialog">
                        OK
                    </Button>
                </Dialog>
        );
    }

    const ButtonCell = () => {
        return <td style={styleCell}>
            <Button style={styleBtn} onClick={viewModalInfo}>{localization.toLanguageString(CLOSE_BUTTON_CONTENT)}</Button>
            </td>
    }
    
    return (
        <div>
            <ObjectEditor data={properties} onUpdate={handleUpdate} orientation="horizontal" />
            <Grid
                data={orderBy(data, sort)}
                className="profit-fixer-table"
                onSortChange={sortChange}
                sort={sort}
                sortable={true}
            >
                <Column title={localization.toLanguageString(NAME)} field="instrument.Name" />
                <Column title={localization.toLanguageString(ACCOUNT)} field="account.Number" />
                <Column title={localization.toLanguageString(ACTION)} field="tradeAction" cell={TradeActionCell} />
                <Column title={localization.toLanguageString(QUANTITY)} field="quantity" cell={QuantityCell}/>
                <Column title={localization.toLanguageString(PRICE)} field="priceDecorator" cell={RightAlignCell}/>
                <Column title={localization.toLanguageString(LAST_PRICE)} field="marketPriceDecorator" cell={RightAlignCell}/>
                <Column title={localization.toLanguageString(DAY_PRICE_CHANGE)} field="dayPriceChange" cell={PercentColorCell} />
                <Column title={localization.toLanguageString(UNREALIZED_PL)} field="pl" cell={NumberCell}/>
                <Column title={localization.toLanguageString(PORTFOLIO_TOTAL_PL_PERCENT)} field="plPercent" cell={PercentCell} />
                <Column title={localization.toLanguageString(TABLE_HEADER_ACTIONS)} cell={ButtonCell} width={120}/>
            </Grid>
            <DialogModalInfo />
        </div>
    );

}

export default OpenExecutionsTable;