import { ButtonGroup, Button } from "@progress/kendo-react-buttons";
import { useLocalization } from "@progress/kendo-react-intl";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dialog } from "@progress/kendo-react-dialogs";
import { Upload } from "@progress/kendo-react-upload";
import { loadInfo, dashboardGenerateDemoData, } from "../../../store/reducers/dashboardReducer/creators";
import { updateAllData } from "../../../store/reducers/generalReducer/generalCreators";

import {
    DASHBOARD_PIE_CHART_HEADER_ASSETS, DASHBOARD_PIE_CHART_HEADER_BROKERS,
    DASHBOARD_PIE_CHART_HEADER_COMPANIES, DASHBOARD_PIE_CHART_HEADER_HOLDERS,
    DASHBOARD_PIE_CHART_HEADER_INSTRUMENTS, DASHBOARD_PIE_CHART_HEADER_SECTORS,
    DASHBOARD_PIE_CHART_HEADER_CURRENCIES,
    GENERATE_DEMO_DATA, DASHBOARD_NO_DATA_TEXT,
    DASHBOARD_IMPORT_STATEMENTS, DASHBOARD_IMPORT_STATEMENT_HEADER, LOADER,
    LOAD_STATEMENT_HELP, DASHBOARD_INDICATORS
} from "../../../infrastructure/localizationKeys";

import LoaderComponent from "../../views/LoaderComponent";
import InfoPanel from "./InfoPanelMobile";
import ProgressComponent from "../../views/ProgressComponent";
import LinksInfo from "./LinksInfo";
import PieChart from "./PieCharts/PieChartMobile";
const saveUrl = "/api/dashboard/upload";

const DashboardM = () => {
    const localization = useLocalization();

    const dispatch = useDispatch();
    const token = useSelector(state => state.login.token);

    const processing = useSelector(state => state.dashboard.processing);
    const progress = useSelector(state => state.dashboard.progress);
    const loading = useSelector(state => state.dashboard.loading);
    const loaded = useSelector(state => state.dashboard.loaded);
    const pies = useSelector(state => state.dashboard.pies);
    const dataExist = useSelector(state => state.dashboard.dataExist);
    const isVisibleLinksInfo = useSelector(state => state.dashboard.isVisibleLinksInfo);
    const error = useSelector(state => state.dashboard.error);

    const statementExist = dataExist;
    const saveHeaders = { Authorization: "Bearer " + token }
    
    useEffect(() => {
        if (!loading && !loaded) {
            dispatch(loadInfo());
        }
    })

    const [visibleModal, setVisibleModal] = useState(false);

    const handleGenerateDemoData = () => {
        dispatch(dashboardGenerateDemoData());
    }

    const GenerateComponent = (
        <Button className="k-mr-3 k-mb-3" onClick={handleGenerateDemoData} disabled={progress}>
            {localization.toLanguageString(GENERATE_DEMO_DATA)}
        </Button>
    );

    const toggleDialog = () => {
        setVisibleModal(!visibleModal);
    };


    const UploadButton = (
        <Button className="k-mr-3 k-mb-3" onClick={toggleDialog} disabled={progress}>
            {localization.toLanguageString(DASHBOARD_IMPORT_STATEMENTS)}
        </Button>
    );

    const DialogModal = () => {
        const UploadComponent = (
            <>
                <div className="link-instruction-report-mobile">
                    <a href="https://investortools.ru/help/statements" target="_blank">{localization.toLanguageString(LOAD_STATEMENT_HELP)}</a>
                </div>
                <Upload
                    disabled={processing}
                    accept=".csv,application/vnd.ms-excel,.xml,.html"
                    autoUpload={false}
                    multiple={true}
                    batch={true}
                    onStatusChange={toggleDialog}
                    actionsLayout="start"
                    defaultFiles={[]}
                    saveUrl={saveUrl}
                    saveHeaders={saveHeaders}
                />
            </>
        );
        return (
            visibleModal &&
            (
                <Dialog title={localization.toLanguageString(DASHBOARD_IMPORT_STATEMENT_HEADER)} modal={true} onClose={toggleDialog} resizable={true} width={window.innerWidth-10}>
                    {UploadComponent}
                </Dialog>
            )
        );
    }

    if (progress != undefined)
        if (!progress.isProcessing && progress.currentStep === progress.stepsCount)
            dispatch(updateAllData());

    const buttons = [
        {
            text: localization.toLanguageString(DASHBOARD_INDICATORS),
            value: "indicators"
        },
        {
            text: localization.toLanguageString(DASHBOARD_PIE_CHART_HEADER_ASSETS),
            value: "assets"
        },
        {
            text: localization.toLanguageString(DASHBOARD_PIE_CHART_HEADER_SECTORS),
            value: "sectors"
        },
        {
            text: localization.toLanguageString(DASHBOARD_PIE_CHART_HEADER_INSTRUMENTS),
            value: "instruments"
        },
        {
            text: localization.toLanguageString(DASHBOARD_PIE_CHART_HEADER_COMPANIES),
            value: "companies"
        },
        {
            text: localization.toLanguageString(DASHBOARD_PIE_CHART_HEADER_CURRENCIES),
            value: "currencies"
        },
        {
            text: localization.toLanguageString(DASHBOARD_PIE_CHART_HEADER_BROKERS),
            value: "brokers"
        },
    ];

    const [select, setSelect] = useState(buttons[0]);

    const handleClick = (value) => {
        let selectButton = buttons.find((m) => m.value === value);
        setSelect(selectButton);
    };

    const SelectedComponent = () => {
        switch (select.value) {
            case "indicators":
                return <InfoPanel />
            case "assets":
                return <PieChart name={localization.toLanguageString(DASHBOARD_PIE_CHART_HEADER_ASSETS)} data={pies.assetsPie} />
            case "brokers":
                return <PieChart name={localization.toLanguageString(DASHBOARD_PIE_CHART_HEADER_BROKERS)} data={pies.brokersPie} />
            case "companies":
                return <PieChart name={localization.toLanguageString(DASHBOARD_PIE_CHART_HEADER_COMPANIES)} data={pies.companiesPie} />
            case "currencies":
                return <PieChart name={localization.toLanguageString(DASHBOARD_PIE_CHART_HEADER_CURRENCIES)} data={pies.сurrenciesPie} />
            case "instruments":
                return <PieChart name={localization.toLanguageString(DASHBOARD_PIE_CHART_HEADER_INSTRUMENTS)} data={pies.instrumentsPie} />
            case "sectors":
                return <PieChart name={localization.toLanguageString(DASHBOARD_PIE_CHART_HEADER_SECTORS)} data={pies.sectorsPie} />
            default:
                return <div />
        }
    }

    return (loading ? <LoaderComponent description={localization.toLanguageString(LOADER)} /> :
        <>
            <div>{statementExist ?
                <>
                    <div className="dashboard-chip-list">
                        <ButtonGroup>
                            {buttons.map((m, index) => {
                                return (
                                    <div key={index}>
                                        <Button
                                            className="btn btn-outline-primary btn-sm"
                                            style={{ margin: "0px 2px 0px 2px" }}
                                            togglable={true}
                                            selected={select.value === m.value}
                                            onClick={handleClick.bind(undefined, m.value)}>
                                            {m.text}
                                        </Button>
                                    </div>
                                );
                            })}
                        </ButtonGroup>
                    </div>
                    <SelectedComponent />
                </>
                :
                <div className="dashboard-no-data-load-mobile">
                    <div style={{ margin: "auto" }}>
                        <p className="dashboard-no-data-text">{localization.toLanguageString(DASHBOARD_NO_DATA_TEXT)}</p>
                        <div>
                            {GenerateComponent}
                        </div>
                        <div>
                            {UploadButton}
                            <DialogModal />
                        </div>
                        <div>
                            {progress && <ProgressComponent progress={progress} error={error} />}
                        </div>
                    </div>
                </div>
            }</div>
            {isVisibleLinksInfo && <LinksInfo />}
        </>
    );
}

export default DashboardM;