import { useSelector } from "react-redux";

const MobilePanelPL = () => {
    const data = useSelector(state => state.profitFixer.data);
    const sortedData = data.sort((a, b) => a.plPercent < b.plPercent ? 1 : -1);

    const percentFormat = new Intl.NumberFormat("en", { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 });
    const numericFormat = new Intl.NumberFormat("en", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 });

    const style = {
        marginTop: "5px",
        border: "1px solid darkgrey",
        borderRadius: "5px",
        padding: "10px 10px 5px 10px"
    }

    const styleLabelLeft = {
        color: "dodgerblue",
        textAlign: "left",
        width: "40%",
        paddingBottom: "5px",
    }

    const styleLabelRight = {
        textAlign: "right",
        width: "60%",
        paddingBottom: "5px",
        fontSize: "90%"
    }

    return (
        <>
            {sortedData.map((x, i) =>
                <div style={style} key={i}>
                    <label style={styleLabelLeft}>
                        {x.instrument.Name}
                    </label>
                    <label style={styleLabelRight}>
                        {numericFormat.format(x.pl)} ({percentFormat.format(x.plPercent)})
                    </label>
                </div>
            )}
        </>
    );
}

export default MobilePanelPL;