import {
    CORRELATION_LOAD_DATA,
    CORRELATION_LOAD_DATA_ERROR,
    CORRELATION_LOAD_DATA_SUCCESS,
    CORRELATION_PROPERTIES_UPDATE,
    INITIAL_CORRELATION_TABLE,
    UPDATE_CORRELATION_DATA,
    UPDATE_STATUS_LOADER,
    CUSTOM_SORT
} from "./actions";

import { LOGOUT, CLEAR_ALL, UPDATE_ALL_DATA } from "../generalReducer/generalActions";

const initial = {
    loaded: false,
    loading: false,
    correlations: [],
    properties: [],
    transparentLoader: false
}


const correlationReducer = (state = initial, action) => {
    switch (action.type) {
        case CORRELATION_LOAD_DATA:
            return {
                ...state,
                loading: true
            }
        case CORRELATION_LOAD_DATA_SUCCESS:
            return {
                ...state,
                loaded: true,
                loading: false,
                ...action.payload
            }
        case CORRELATION_PROPERTIES_UPDATE:
            return {
                ...state,
                ...action.payload
            }
        case CORRELATION_LOAD_DATA_ERROR:
            return {
                ...state,
                loaded: true,
                loading: false,
            }
        case INITIAL_CORRELATION_TABLE:
            return {
                ...state,
                correlations: action.payload
            }
        case UPDATE_CORRELATION_DATA:
            return{
                ...state,
                correlations: state.correlations.map(x => x.header === action.payload.header ? action.payload : x)
            }
        case UPDATE_STATUS_LOADER:
            return {
                ...state,
                transparentLoader: action.payload
            }
        case CUSTOM_SORT:
            let correlationModels = state.correlations?.sort((a, b) => a.header > b.header ? 1 : -1);

            if (action.payload === undefined)
                return {
                    ...state,
                    correlations: correlationModels
                }

            const sortVal = action.payload.dir === "asc" ? 1 : -1;
            const defaultValue = action.payload.dir === "asc" ? 10 : -10;

            if (action.payload.field === "header") {
                correlationModels = correlationModels.sort((a, b) => a.header > b.header ? sortVal : -sortVal);
            } else {
                const sortList = correlationModels.find(x => x.header === action.payload.field)?.listInstrumentCoeffCorrelation?.map(x => { return x.value });

                let index = 0;
                correlationModels.forEach(x => x.sortedValue = x.header != action.payload.field ? sortList[index++] : defaultValue);

                correlationModels = correlationModels.sort((a, b) => a.sortedValue > b.sortedValue ? sortVal : -sortVal);
            }
            return {
                ...state,
                correlations: correlationModels
            }
        case UPDATE_ALL_DATA:
            return {
                ...state,
                loading: false,
                loaded: false
            }
        case CLEAR_ALL:
            return initial;
        case LOGOUT:
            return initial;
        default:
            return state;
    }
}

export default correlationReducer;