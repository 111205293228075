import { useLocalization } from "@progress/kendo-react-intl";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useSelector } from "react-redux";
import {
    DASHBOARD_INFOPANEL_INVESTING_PERIOD,
    DASHBOARD_INFOPANEL_NET_ASSET_VALUE,
    DASHBOARD_INFOPANEL_TOTAL_PNL,
    DASHBOARD_INFOPANEL_VOLATILITY,
    DASHBOARD_INFOPANEL_SHARP,
    DASHBOARD_INFOPANEL_BETA
} from "../../../infrastructure/localizationKeys";

const InfoPanel = () => {
    const localization = useLocalization();
    const info = useSelector(state => state.dashboard.info);

    const Panel = ({ header, value, description, link }) => {
        return (
            <div className="info-panel-border">
                <p className="info-panel-text-header">
                    <nobr>{header}
                        <sup>{link != null ? <a href={link} className="dashboard-link" target="_blank">?</a> : ""}</sup>
                    </nobr>
                </p>
                <p className="info-panel-text-value">
                    <nobr>{value}</nobr>
                </p>
                <p className="info-panel-text-description">
                    <nobr>{description}</nobr>
                </p>
            </div>
        )
    }

    const PanelUpdate = ({ header, value }) => {
        const todayChange = useSelector(state => state.dashboard.todayChange);
        return (
            <div className="info-panel-border">
                <p className="info-panel-text-header">
                    <nobr>{header}</nobr>
                </p>
                <p className="info-panel-text-value">
                    <nobr>{value}</nobr>
                </p>
                <p className="info-panel-text-description">
                    <nobr>{todayChange}</nobr>
                </p>
            </div>
        )
    }
    

    return (
        <GridLayout align={{ vertical: "stretch" }} cols={[{ width: "33.3%" }, { width: "33.3%" }, { width: "33.3%" }]} gap={{ rows: 1, cols: 3 }}>
            <GridLayoutItem row={1} col={1}>
                <GridLayout cols={[{ width: "50%" }, { width: "50%" }]} gap={{ rows: 1, cols: 2 }}>
                    <GridLayoutItem row={1} col={1}>
                        <PanelUpdate header={localization.toLanguageString(DASHBOARD_INFOPANEL_NET_ASSET_VALUE)} value={info.netAssetValue} />
                    </GridLayoutItem>
                    <GridLayoutItem row={1} col={2}>
                        <Panel header={localization.toLanguageString(DASHBOARD_INFOPANEL_TOTAL_PNL)} value={info.totalPlPercent} description={info.totalPlAmount} link={null} />
                    </GridLayoutItem>
                </GridLayout>
            </GridLayoutItem>
            <GridLayoutItem row={1} col={2}>
                <GridLayout cols={[{ width: "50%" }, { width: "50%" }]} gap={{ rows: 1, cols: 2 }}>
                    <GridLayoutItem row={1} col={1}>
                        <Panel header={localization.toLanguageString(DASHBOARD_INFOPANEL_INVESTING_PERIOD)} value={info.investingStartInfo} description={info.investingDays} link={null} />
                    </GridLayoutItem>
                    <GridLayoutItem row={1} col={2}>
                        <Panel header={localization.toLanguageString(DASHBOARD_INFOPANEL_SHARP)} value={info.sharpRatio} description={info.sortino} link="https://investortools.ru/help/sharp" />
                    </GridLayoutItem>
                </GridLayout>
            </GridLayoutItem>
            <GridLayoutItem row={1} col={3}>
                <GridLayout cols={[{ width: "50%" }, { width: "50%" }]} gap={{ rows: 1, cols: 2 }}>
                    <GridLayoutItem row={1} col={1}>
                        <Panel header={localization.toLanguageString(DASHBOARD_INFOPANEL_VOLATILITY)} value={info.volatility} description={info.drawdown} link="https://investortools.ru/help/volatility" />
                    </GridLayoutItem>
                    <GridLayoutItem row={1} col={2}>
                        <Panel header={localization.toLanguageString(DASHBOARD_INFOPANEL_BETA)} value={info.beta} description={info.betaLastMonth} link="https://investortools.ru/help/beta" />
                    </GridLayoutItem>
                </GridLayout>
            </GridLayoutItem>
        </GridLayout>
    )
}

export default InfoPanel;