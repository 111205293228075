import { useSelector } from "react-redux";
import { useState, useCallback } from "react";
import { useLocalization } from "@progress/kendo-react-intl";
import { Grid, GridColumn as Column, getSelectedState } from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { RightAlignCell } from "../../../views/DataGridCells";
import { getPositiveNegativeColor, getBlackWhiteColor } from "../../../../infrastructure/gradientPercentToColor";
import { TYPE, TOTAL, ACCOUNT, TIME, NAME, AMOUNT } from "../../../../infrastructure/localizationKeys";


import { DateTimeCell, DateTimeCellType } from "../../../views/DataGridCells";

const DateCell = (props) => {
    return <DateTimeCell {...props} type={DateTimeCellType.DATE} />
}


const MoneyTab = (props) => {
    const localization = useLocalization();
    const currencies = useSelector(state => state.equity.currencies);

    const [events, setEvents] = useState(props.data[0]?.events);

    const [isVisibleShortName, setVisibleShortName] = useState(events != undefined ? events[0]?.ShortName != null : false);

    const handleSelectedMoney = (event) => {
        setEvents(event.dataItem.events);
        if (event.dataItem.events != undefined)
            setVisibleShortName(event.dataItem.events[0]?.ShortName != null);
    }

    const DATA_ITEM_KEY = "type";
    const SELECTED_FIELD = "selected";
    const idGetter = getter(DATA_ITEM_KEY);

    const [selectedState, setSelectedState] = useState({});

    
    const onSelectionChange = useCallback(
        (event) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });
            
            setSelectedState(newSelectedState);
        },
        [selectedState]
    );

    const isEmptyObj = (object) => {
        for (let key in object) {
            if (object.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }

    let classname = props.classname + " cursor-pointer";
    return (
        <GridLayout>
            <GridLayoutItem row={1} col={1} style={{ marginRight: "5px" }}>
                <Grid
                    data={props.data.map((item, i) => ({
                        ...item,
                        [SELECTED_FIELD]: isEmptyObj(selectedState) && i === 0 ? true : selectedState[idGetter(item)],
                    }))}
                    onRowClick={handleSelectedMoney}
                    style={{ height: props.height }}
                    className={classname}
                    dataItemKey={DATA_ITEM_KEY}
                    selectedField={SELECTED_FIELD}
                    selectable={{
                        enabled: true,
                        drag: false,
                        cell: false,
                        mode: "single",
                    }}
                    onSelectionChange={onSelectionChange}
                >
                    <Column field="type" title={localization.toLanguageString(TYPE)} />
                    {
                        currencies.map(x =>
                            <Column
                                key={x}
                                field={x}
                                title={x}
                                cell={CustomCell}
                            />
                        )
                    }
                    <Column field="totalValueString" title={localization.toLanguageString(TOTAL)} cell={(props) => {

                        const offset = props.dataItem.cells.Total.CellGradientOffset;
                        const color = getBlackWhiteColor(offset)
                        const background = getPositiveNegativeColor(offset);

                        return <RightAlignCell {...props} style={{ color, background }} onClick={props.selectionChange} />
                    }} />
                </Grid>
            </GridLayoutItem>
            <GridLayoutItem row={1} col={2} style={{ marginLeft: "5px" }}>
                <Grid data={events} style={{ height: props.height }}>
                    <Column title={localization.toLanguageString(TIME)} field="DateTime" cell={DateCell} />
                    <Column title={localization.toLanguageString(ACCOUNT)} field="Number" />
                    {isVisibleShortName && <Column title={localization.toLanguageString(NAME)} field="ShortName" />}
                    <Column title={localization.toLanguageString(AMOUNT)} field="ValueString" />
                </Grid>
            </GridLayoutItem>
        </GridLayout>
    )
}

const CustomCell = (props) => {
    const cell = props.dataItem.cells[props.field];

    if (cell === undefined) {
        return <td onClick={props.selectionChange} />
    }

    const localization = useSelector(state => state.localization);
    const culture = localization.culture;
    const amount = cell.Amount;
    const symbol = props.dataItem.displayUnit === "Currency" ? cell.CurrencySymbol : "%";
    const numericFormat = new Intl.NumberFormat(culture, { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 });
    const value = `${numericFormat.format(amount)} ${symbol}`;
    const offset = cell.CellGradientOffset;
    const color = getBlackWhiteColor(offset)
    const background = getPositiveNegativeColor(offset);

    return (<td onClick={props.selectionChange} style={{ color, background }}>{value}</td>)
}

export default MoneyTab;