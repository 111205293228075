import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocalization } from "@progress/kendo-react-intl";

import { profitFixerLoad, profitFixerPropertiesUpdate } from "../../../store/reducers/profitFixerReducer/creators";
import { LOADER } from "../../../infrastructure/localizationKeys";
import LoaderComponent from "../../views/LoaderComponent";
import ProfitFixerProperties from "./ProfitFixerProperties";
import MobilePanelPL from "./MobilePanelPL";

const ProfitFixerM = () => {
    const dispatch = useDispatch();
    const localization = useLocalization();

    const loading = useSelector(state => state.profitFixer.loading);
    const loaded = useSelector(state => state.profitFixer.loaded);
    
    const properties = useSelector(state => state.profitFixer.properties);

    const handleUpdate = (properties) => {
        dispatch(profitFixerPropertiesUpdate(properties));
    }
    
    useEffect(() => {
        if (!loading && !loaded) {
            dispatch(profitFixerLoad());
        }
    })

    return (loading ?
        <LoaderComponent description={localization.toLanguageString(LOADER)} />
        :
        <>
            <ProfitFixerProperties data={properties} onUpdate={handleUpdate} />
            <MobilePanelPL />
        </>
    )
}

export default ProfitFixerM;