import { useSelector } from "react-redux";
import { isMobile } from 'react-device-detect';

const CashBalances = () => {
    const cashBalances = useSelector(state => state.portfolio.cashBalances);

    const numericFormat = new Intl.NumberFormat("en", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 });

    let text = ""

    cashBalances.map(x => text += x.currency + " " + numericFormat.format(x.balance) + "     ");

    return (cashBalances.length > 0 ?
        <div className="cash-balances-info">
            <div style={{ display: "grid" }}>
                <pre style={{ marginBottom: "0px", paddingBottom: "5px" }}>{text}</pre>
            </div>
        </div>
        : <div/>
    );
}

export default CashBalances;