const hex = c => {
    const s = "0123456789abcdef";
    let i = parseInt(c);
    if (i === 0 || isNaN(c))
        return "00";
    i = Math.round(Math.min(Math.max(0, i), 255));
    return s.charAt((i - i % 16) / 16) + s.charAt(i % 16);
}

/* Convert an RGB triplet to a hex string */
const convertToHex = rgb => "#" + hex(rgb[0]) + hex(rgb[1]) + hex(rgb[2]);

/* Remove '#' in color hex string */
const trim = s => s.charAt(0) == '#' ? s.substring(1, 7) : s 

/* Convert a hex string to an RGB triplet */
const convertToRGB = hex => {
    const color = [];
    color[0] = parseInt((trim(hex)).substring(0, 2), 16);
    color[1] = parseInt((trim(hex)).substring(2, 4), 16);
    color[2] = parseInt((trim(hex)).substring(4, 6), 16);
    return color;
}

const normalizeOffset = offset => {
    if (offset < 0)
        offset = 0;
    if (offset > 1)
        offset = 1;
    return offset;
}

export const getGradientColor = (colorStart, colorEnd, offset) => {
    const start = convertToRGB(colorStart);
    const end = convertToRGB(colorEnd);
    const c = [];

    c[0] = start[0] * offset + (1 - offset) * end[0];
    c[1] = start[1] * offset + (1 - offset) * end[1];
    c[2] = start[2] * offset + (1 - offset) * end[2];

    return convertToHex(c);
}

export const getGradientWhiteOrBlack = (offset) => {
    const c = [];

    c[0] = c[1] = c[2] = 255 * offset <= 125? 0 : 255;
    
    return convertToHex(c);
}

const endPositive = "#90ee90"; // lightgreen
const startPositive = "#006400"; // darkgreen

const endNegative = "#db7093"; // palevioletred
const startNegative = "#8b0000"; // darkred

const white = "#ffffff";
const black = "#000000";

export const getPositiveGradientColor = (offset) => getGradientColor(startPositive, endPositive, normalizeOffset(offset));

export const getNegativeGradientColor = (offset) => getGradientColor(startNegative, endNegative, normalizeOffset(offset));

export const getBlackWhiteColor = (offset) => getGradientWhiteOrBlack(normalizeOffset(Math.abs(offset)));

export const getPositiveNegativeColor = (offset) => {
    if (offset === 0)
        return null
    else if (offset < 0)
        return getNegativeGradientColor(-offset);
    else
        return getPositiveGradientColor(offset);
}