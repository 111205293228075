import { fork, all, takeEvery } from 'redux-saga/effects';
import { PORTFOLIO_LOAD_DATA, PORTFOLIO_SET_PROPERTIES } from "../../reducers/portfolioReducer/actions";
import { loadProtfolioSuccess, loadProtfolioError } from "../../reducers/portfolioReducer/creators";
import { requset } from "../infrastructure";

const baseUrl = "/api/portfolio";
const propertyUrl = `${baseUrl}/property`

// WORKER SAGAS
function* loadPortfolio() {
    yield requset(baseUrl, "GET", loadProtfolioSuccess, loadProtfolioError);
}
function* setProperties({ payload }) {
    yield requset(propertyUrl, "PUT", null, null, payload);
}

// WATCHER SAGAS
function* loadPortfolioSaga() {
    yield takeEvery(PORTFOLIO_LOAD_DATA, loadPortfolio);
}
function* setPropertiesSaga() {
    yield takeEvery(PORTFOLIO_SET_PROPERTIES, setProperties)
}

// ROOT SAGA
export default function* portfolioSaga() {
    yield all([
        fork(loadPortfolioSaga),
        fork(setPropertiesSaga)
    ]);
}