import { UPDATE, LOGOUT, CHANGE_EXPAND_MENU, CLEAR_ALL, UPDATE_ALL_DATA } from "./generalActions";

export const logout = () => ({
    type: LOGOUT
})

export const update = () => ({
    type: UPDATE
});

export const changeExpand = () => ({
    type: CHANGE_EXPAND_MENU
});

export const clearData = () =>({
    type: CLEAR_ALL
});

export const updateAllData = () => ({
    type: UPDATE_ALL_DATA
})