import { combineReducers } from 'redux';

import loginReducer from "./loginReducer";
import dashboardReducer from "./dashboardReducer";
import usersReducer from "./usersReducer";
import accountsReducer from "./accountsReducer";
import portfolioReducer from "./portfolioReducer";
import enumReducer from "./enumReducer";
import localizationReducer from "./localizationReducer";
import payoutsReducer from "./payoutsReducer";
import equityReducer from "./equityReducer";
import ideasReducer from "./ideasReducer";
import errorsReducer from "./errorsReducer";
import generalReducer from "./generalReducer";
import usageReducer from "./usageReducer";
import profitFixerReducer from "./profitFixerReducer";
import restorePositionsReducer from "./restorePositionsReducer";
import correlationReducer from "./correlationReducer"

const rootReducer = combineReducers({
    login: loginReducer,
    dashboard: dashboardReducer,
    users: usersReducer,
    portfolio: portfolioReducer,
    accounts: accountsReducer,
    enums: enumReducer,
    localization: localizationReducer,
    payouts: payoutsReducer,
    equity: equityReducer,
    ideas: ideasReducer,
    profitFixer: profitFixerReducer,
    restorePositions: restorePositionsReducer,
    correlation: correlationReducer,
    errors: errorsReducer,
    general: generalReducer,
    usage: usageReducer,
});

export default rootReducer;
