import { useSelector } from "react-redux";
import { useState } from "react";
import { useLocalization } from "@progress/kendo-react-intl";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";

import { DateTimeCell, DateTimeCellType, QuantityCell, RightAlignCell } from "../../views/DataGridCells";

import {
    SYMBOL, NAME, QUANTITY, AVERAGE_PRICE, LAST_PRICE,
    DAYS_TILL_OFFER, OFFER_DATE, NOMINAL_PRICE, UPCOMING_OFFERS
} from "../../../infrastructure/localizationKeys";

const DateCell = (props) => {
    return <DateTimeCell {...props} type={DateTimeCellType.DATE} />
}

const BondsTable = (props) => {
    const localization = useLocalization();
    const bondsWithOfferDate = useSelector(state => state.payouts.bondsWithOfferDate);

    const initialSort = [
        {
            field: "daysTillOffer",
            dir: "asc",
        },
    ];

    const [sort, setSort] = useState(initialSort);

    let tabHeight = (props.height.replace("px", '') - 81) + "px";
    let gridHeight = (props.height.replace("px", '') - 122) + "px";

    return(
        <div style={{ height: tabHeight }} className="bonds-panel">
            <div className="upcoming-offers">{localization.toLanguageString(UPCOMING_OFFERS)}</div>
            <Grid
                data={orderBy(bondsWithOfferDate, sort)}
                className="payouts-text-size"
                style={{ height: gridHeight }}
                sortable={true}
                sort={sort}
                onSortChange={(e) => {
                    setSort(e.sort);
                }}
            >
                <Column title={localization.toLanguageString(SYMBOL)} field="symbol" />
                <Column title={localization.toLanguageString(NAME)} field="shortName" />
                <Column title={localization.toLanguageString(OFFER_DATE)} field="offerDate" cell={DateCell} />
                <Column title={localization.toLanguageString(DAYS_TILL_OFFER)} field="daysTillOffer" cell={QuantityCell} />
                <Column title={localization.toLanguageString(QUANTITY)} field="quantity" cell={QuantityCell}/>
                <Column title={localization.toLanguageString(AVERAGE_PRICE)} field="averagePrice" cell={RightAlignCell} />
                <Column title={localization.toLanguageString(LAST_PRICE)} field="currentPrice" cell={RightAlignCell} />
                <Column title={localization.toLanguageString(NOMINAL_PRICE)} field="nominalPrice" cell={RightAlignCell} />
            </Grid>
        </div>
    );
}

export default BondsTable;