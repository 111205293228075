import { fork, all, takeEvery } from 'redux-saga/effects';
import { loadUsageSuccess, loadUserUsageSuccess, loadFuncUsageSuccess } from "../../reducers/usageReducer/creators";
import { LOAD_USAGE, LOAD_FUNC_USAGE, LOAD_USER_USAGE } from "../../reducers/usageReducer/actions";
import { requset } from "../infrastructure";

const baseUrl = "/api/usage";
const userUsageUrl = (id) => `${baseUrl}/user/${id}`;
const funcUsageUrl = (func) => `${baseUrl}/func/${func}`;

// WORKER SAGAS
function* loadUsage() {
    yield requset(baseUrl, "GET", loadUsageSuccess);
}
function* loadUserUsage({ payload }) {
    yield requset(userUsageUrl(payload), "GET", loadUserUsageSuccess);
}
function* loadFuncUsage({ payload }) {
    yield requset(funcUsageUrl(payload), "GET", loadFuncUsageSuccess);
}

// WATCHER SAGAS
function* loadUsageSaga() {
    yield takeEvery(LOAD_USAGE, loadUsage);
}
function* loadUserUsageSaga() {
    yield takeEvery(LOAD_USER_USAGE, loadUserUsage);
}
function* loadFuncUsageSaga() {
    yield takeEvery(LOAD_FUNC_USAGE, loadFuncUsage);
}

// ROOT SAGA
export default function* usageSaga() {
    yield all([
        fork(loadUsageSaga),
        fork(loadUserUsageSaga),
        fork(loadFuncUsageSaga),
    ]);
}