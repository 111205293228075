import { useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";

import { Dialog } from "@progress/kendo-react-dialogs";

import ObjectEditor from "../../views/ObjectEditor";

const PayoutsProperties = (props) => {
    const [data, setData] = useState(props.data.map(x => ({ ...x })));

    const periods = useSelector(state => state.enums[props.data[5]?.propertyType]);

    const display = periods?.find(x => x.key === props.data[5]?.value)?.display;
    const value = { key: props.data[5]?.value, display };

    const [visibleModal, setVisibleModal] = useState(false);

    const onPropertyEdit = (name, value) => {
        const result = data.map(x => x.propertyName === name ? { ...x, value } : { ...x });
        setData(result);
        props.onUpdate(result);
    }

    const updateHandler = (result) => {
        setData(result);
        props.onUpdate(result);
    }

    const styleButton = {
        width: "auto",
        height: "30px",
        fontSize: "90%",
        marginLeft: "5px"
    }

    const styleDrown = {
        width: "auto",
        height: "30px",
        fontSize: "90%",
        marginLeft: "5px"
    }

    const styleDrownPeriod = {
        width: "150px",
        height: "30px",
        fontSize: "90%",
        marginLeft: "5px"
    }

    const handleDrownListChange = (event) => {
        onPropertyEdit(props.data[0].propertyName, event.target.value);
    }

    const handlePeriodChange = (event) => {
        onPropertyEdit(props.data[5].propertyName, event.target.value.key);
    }

    const styleLine = {
        display: "inline-block",

    }

    const toggleDialog = () => {
        setVisibleModal(!visibleModal);
    };

    const DialogModal = () => {
        return (visibleModal &&
            <Dialog title=" " modal={true} onClose={toggleDialog} resizable={true} width={window.innerWidth - 10}>
                <ObjectEditor data={props.data} onUpdate={updateHandler} orientation="vertical" />
            </Dialog>
        );
    }

    return (periods != undefined ?

        <div style={styleLine}>
            <DropDownList
                onChange={handleDrownListChange}
                data={props.data[0].collection}
                value={props.data[0].value}
                style={styleDrown}
            />
            <DropDownList
                style={styleDrownPeriod}
                onChange={handlePeriodChange}
                textField="display"
                dataItemKey="key"
                data={periods}
                value={value}
            />
            <Button style={styleButton} icon="k-icon k-i-gear" onClick={toggleDialog}></Button>
            <DialogModal />
        </div>
        :
        <div />)


}
export default PayoutsProperties;