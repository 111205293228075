import { useState } from "react";

import { useLocalization } from "@progress/kendo-react-intl";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";

import PortfolioPayouts from "./PortfolioPayouts";
import PortfolioExecutions from "./PortfolioExecutions";
import PortfolioAccounts from "./PortfolioAccounts";

import { PAYOUTS, EXECUTIONS, ACCOUNTS_HEADER } from "../../../../infrastructure/localizationKeys";

const PortfolioDetail = (props) => {
    const [selected, setSelected] = useState(0);
    const localization = useLocalization();

    const { executions, payouts, childPositions } = props.dataItem;
    const handleSelect = (event) => setSelected(event.selected);

    return (
        <TabStrip selected={selected} onSelect={handleSelect}>
            <TabStripTab title={localization.toLanguageString(ACCOUNTS_HEADER)}>
                <PortfolioAccounts data={childPositions} />
            </TabStripTab>
            <TabStripTab title={localization.toLanguageString(EXECUTIONS)}>
                <PortfolioExecutions data={executions} />
            </TabStripTab>
            <TabStripTab title={localization.toLanguageString(PAYOUTS)} >
                <PortfolioPayouts data={payouts} />
            </TabStripTab>
        </TabStrip>
    );
};

export default PortfolioDetail;