import {
    EQUITY_LOAD_DATA, EQUITY_LOAD_DATA_ERROR, EQUITY_LOAD_DATA_SUCCESS,
    EQUITY_UPDATE_PROPERTIES_SUCCESS,
    EQUITY_UPDATE_PERIOD,
    EQUITY_ADD_PERIOD_SUCCESS, EQUITY_REMOVE_PERIOD,
    EQUITY_TAB_SELECT,
    EQUITY_INSTRUMENT_DETAILS_SUCCESS,
    EQUITY_REMOVE_DETAILS_TAB, EQUITY_INSTRUMENT_DETAILS,
    EQUITY_UPDATE_PROPERTIES
} from "./actions";

import { LOGOUT, CLEAR_ALL, UPDATE_ALL_DATA } from "../generalReducer/generalActions";

const initial = {
    loading: false,
    loaded: false,
    error: false,
    loader: true,
    tabSelected: 0,
    properties: {},
    currencies: [],
    equity: [],
    money: [],
    periods: [],
    customPeriods: [],
    instruments: [],
    fromDate: null,
    toDate: null,
    listTab: [],
    periodRemoved: null,
};

const equityReducer = (state = initial, action) => {
    switch (action.type) {

        case EQUITY_LOAD_DATA:
            return {
                ...state,
                loading: true,
                loaded: false,
                loader: true
            }
        case EQUITY_LOAD_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                error: false,
                loader: false,
                ...action.payload
            }

        case EQUITY_LOAD_DATA_ERROR:
            return {
                ...state,
                loading: false,
                loaded: true,
                error: true,
            }
        case EQUITY_UPDATE_PROPERTIES:
            return {
                ...state,
                loader: true,
                properties: action.payload
            }
        case EQUITY_UPDATE_PROPERTIES_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loader: false
            }

        case EQUITY_UPDATE_PERIOD:
            return {
                ...state,
                ...action.payload
            }

        case EQUITY_ADD_PERIOD_SUCCESS:
            return {
                ...state,
                tabSelected: state.listTab.length + 3,
                listTab: [...state.listTab, action.payload]
            }

        case EQUITY_REMOVE_PERIOD: {
            const removeIndex = state.listTab.indexOf(action.payload) + 3;
            const result = {
                ...state,
                listTab: [...state.listTab.filter(period => period != action.payload)]
            }
            if (removeIndex === state.tabSelected) {
                result.tabSelected--;
            } else {
                result.periodRemoved = removeIndex;
            }
            return result;
        }

        case EQUITY_TAB_SELECT: {
            const result = { ...state };

            if (state.periodRemoved) {
                if (state.periodRemoved < state.tabSelected) {
                    result.tabSelected--;
                }
                result.periodRemoved = null;
            } else {
                result.tabSelected = action.payload;
            }

            return result;
        }
        case EQUITY_INSTRUMENT_DETAILS:
            return {
                ...state,
            }
        case EQUITY_INSTRUMENT_DETAILS_SUCCESS:
            
            return {
                ...state,
                tabSelected: state.listTab.length + 3,
                listTab: [...state.listTab, action.payload]
            }
            
        case EQUITY_REMOVE_DETAILS_TAB:
            const removeIndex = state.listTab.indexOf(action.payload) + 3;
            const result = {
                ...state,
                listTab: [...state.listTab.filter(period => period != action.payload)]
            }
            if (removeIndex === state.tabSelected) {
                result.tabSelected--;
            } else {
                result.periodRemoved = removeIndex;
            }
            return result;
        case UPDATE_ALL_DATA:
            return {
                ...state,
                loading: false,
                loaded: false
            }
        case CLEAR_ALL:
            return initial;
        case LOGOUT:
            return initial;
        default:
            return state;
    }
}

export default equityReducer;