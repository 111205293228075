import { useState } from "react";
import { useDispatch } from "react-redux";

import { useLocalization } from "@progress/kendo-react-intl";
import { orderBy } from "@progress/kendo-data-query";

import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { QuantityCell, NumberCell, PercentCell, DateTimeCell, DateTimeCellType } from "../../views/DataGridCells";
import PortfolioDetail from "./PortfolioDetail";
import TotalLabel from "./TotalLabel";

import {
    SYMBOL, NAME, SHARE, QUANTITY, AVERAGE_PRICE, LAST_PRICE, DAY_PRICE_CHANGE,
    REALIZED_PL, UNREALIZED_PL, TOTAL_PL, PORTFOLIO_EPIRATION_DATE, PORTFOLIO_INVESTED_VALUE,
    PORTFOLIO_MARKET_VALUE, PORTFOLIO_TOTAL_PL_PERCENT
}
    from "../../../infrastructure/localizationKeys";

import { portfolioSetData } from "../../../store/reducers/portfolioReducer/creators";


const DateCell = (props) => {
    return <DateTimeCell {...props} type={DateTimeCellType.DATE} />
}

const NumberColorCell = (props) => {
    return <NumberCell {...props} colored={true} />
}

const NumericItalicCell = (props) => {
    return <NumberCell {...props} colored={true} isItalic={true} />
}

const PercentColorCell = (props) => {
    return <PercentCell {...props} colored={true} />
}

const PortfolioTable = (props) => {
    const [sort, setSort] = useState([]);
    const dispatch = useDispatch();
    const localization = useLocalization();

    const expandChange = (event) => {
        event.dataItem.expanded = event.value;
        dispatch(portfolioSetData());
    };
    const sortChange = (event) => setSort(event.sort);

    const [isVisibleDateExpiration, setVisibleDateExpiration] = useState(props.data.positions[0]?.instrument.ExpirationDate != undefined ? true : false);

    return (
        <>
            <div className="portfolio-content">
                <Grid
                    data={orderBy(props.data.positions, sort)}
                    detail={PortfolioDetail}
                    expandField="expanded"
                    onExpandChange={expandChange}
                    onSortChange={sortChange}
                    sort={sort}
                    sortable={true}
                    className="portfolio-content-table"
                >
                    <Column field="instrument.Name" title={localization.toLanguageString(NAME)} width={150} />
                    <Column field="instrument.Symbol" title={localization.toLanguageString(SYMBOL)} />
                    {isVisibleDateExpiration && <Column field="instrument.ExpirationDate" title={localization.toLanguageString(PORTFOLIO_EPIRATION_DATE)} cell={DateCell} />}
                    <Column field="portfolioShare" title={localization.toLanguageString(SHARE)} cell={PercentCell} width={80} />
                    <Column field="quantity" title={localization.toLanguageString(QUANTITY)} cell={QuantityCell} width={110} />
                    <Column field="averagePrice" title={localization.toLanguageString(AVERAGE_PRICE)} cell={NumberCell} />
                    <Column field="lastPriceDecorator.Money" title={localization.toLanguageString(LAST_PRICE)} cell={NumericItalicCell} />
                    <Column field="dayPriceChange" title={localization.toLanguageString(DAY_PRICE_CHANGE)} cell={PercentColorCell} width={105} />
                    <Column field="investedValue" title={localization.toLanguageString(PORTFOLIO_INVESTED_VALUE)} cell={NumberCell} />
                    <Column field="marketValue" title={localization.toLanguageString(PORTFOLIO_MARKET_VALUE)} cell={NumberCell} />
                    <Column field="realizedPl" title={localization.toLanguageString(REALIZED_PL)} cell={NumberColorCell} />
                    <Column field="unrealizedPl" title={localization.toLanguageString(UNREALIZED_PL)} cell={NumberColorCell} />
                    <Column field="totalPl" title={localization.toLanguageString(TOTAL_PL)} cell={NumberColorCell} />
                    <Column field="totalPlPercent" title={localization.toLanguageString(PORTFOLIO_TOTAL_PL_PERCENT)} cell={PercentColorCell} width={100} />
                </Grid>
                <TotalLabel data={props.data.positions} />
            </div>
        </>
    )
}

export default PortfolioTable;