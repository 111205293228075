import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocalization } from "@progress/kendo-react-intl";
import PortfolioTabsMobile from "./PortfolioTabsMobile";
import PortfolioProperties from "./PortfolioProperties";
import CashBalances from "./CashBalances";



import { LOADER } from "../../../infrastructure/localizationKeys";
import { loadProtfolio } from "../../../store/reducers/portfolioReducer/creators";
import LoaderComponent from "../../views/LoaderComponent";

const PortfolioM = () => {
    const localization = useLocalization();
    const dispatch = useDispatch();

    const loading = useSelector(state => state.portfolio.loading);
    const loaded = useSelector(state => state.portfolio.loaded);

    useEffect(() => {
        if (!loading && !loaded) {
            dispatch(loadProtfolio())
        }
    })

    return (
        <>
            {loading ? <LoaderComponent description={localization.toLanguageString(LOADER)} /> : (
                <>
                    <PortfolioProperties />
                    <CashBalances />
                    <PortfolioTabsMobile />
                </>
            )}
        </>
    );
}

export default PortfolioM;