import {
    DASHBOARD_LOAD_DATA, DASHBOARD_LOAD_DATA_SUCCESS, DASHBOARD_LOAD_DATA_ERROR,
    DASHBOARD_INFO_LINKS_HIDE,
    DASHBOARD_GENERATE_DEMO_DATA,
    DASHBOARD_PROGRESS_CHANGE,
    DASHBOARD_TODAY_CHANGE_UPDATE
} from "./actions";

import { LOGOUT, CLEAR_ALL, UPDATE_ALL_DATA } from "../generalReducer/generalActions";

const initial = {
    processing: false,

    error: false,
    loaded: false,
    loading: false,

    progress: undefined,

    info: [],
    pies: undefined,
    todayChange: undefined,
    dataExist: undefined,
    isVisibleLinksInfo: undefined,
    linksUsedDataSources: undefined
};

function dashboardReducer(state = initial, action) {
    switch (action.type) {

        // LOAD DATA
        case DASHBOARD_LOAD_DATA:
            return {
                ...state,
                loading: true,
                loaded: false,
                error: false,
                progress: undefined,
            }
        case DASHBOARD_LOAD_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload,

                loading: false,
                loaded: true,
                error: false,
                processing: false,
                progress: undefined
            }
        case DASHBOARD_LOAD_DATA_ERROR:
            return {
                ...state,
                loading: false,
                loaded: true,
                error: true,
                processing: false,
            }

        // HIDE LINKS
        case DASHBOARD_INFO_LINKS_HIDE:
            return {
                ...state,
                isVisibleLinksInfo: false,
            }

        // GENERATE DATA
        case DASHBOARD_GENERATE_DEMO_DATA:
            return {
                ...state,
                error: false,
                processing: true
            }

        // PROGRESS CHANGE
        case DASHBOARD_PROGRESS_CHANGE:
            return {
                ...state,
                ...action.payload
            }
        case UPDATE_ALL_DATA:
            return {
                ...state,
                loading: false,
                loaded: false,
                progress: undefined,
            }
        case CLEAR_ALL:
            return initial;
        case DASHBOARD_TODAY_CHANGE_UPDATE:
            return {
                ...state,
                todayChange: action.payload,
            }
        case LOGOUT:
            return initial;
        default:
            return state;
    }
}

export default dashboardReducer;