import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import UsersGrid from "./UsersGrid";

import { loadUsers } from "../../../store/reducers/usersReducer/creators";

function Users() {
    const loaded = useSelector(state => state.users.loaded)
    const loading = useSelector(state => state.users.loading)
    const dispatch = useDispatch();

    useEffect(() => {
        if (!loaded && !loading)
            dispatch(loadUsers());
    });

    return <UsersGrid />
}

export default Users;