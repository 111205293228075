import {
    PORTFOLIO_LOAD_DATA, PORTFOLIO_LOAD_DATA_SUCCESS, PORTFOLIO_LOAD_DATA_ERROR,
    PORTFOLIO_UPDATE_DATA, PORTFOLIO_SET_DATA, PORTFOLIO_SET_PROPERTIES
} from "./actions";

// LOAD DATA
export const loadProtfolio = () => ({
    type: PORTFOLIO_LOAD_DATA,
});
export const loadProtfolioSuccess = (data) => ({
    type: PORTFOLIO_LOAD_DATA_SUCCESS,
    payload: data
});
export const loadProtfolioError = () => ({
    type: PORTFOLIO_LOAD_DATA_ERROR,
});

// PROPERIES UPDATE
export const portfolioSetProperties = (data) => ({
    type: PORTFOLIO_SET_PROPERTIES,
    payload: data
})
export const portfolioSetData = () => ({
    type: PORTFOLIO_SET_DATA,
});

// PORTFOLIO UPDATE DATA
export const portfolioUpdateData = (args) => ({
    type: PORTFOLIO_UPDATE_DATA,
    payload: args
});

