import "./css/tfi-theme/dist/css/tfi-theme.css"
import 'bootstrap/dist/css/bootstrap.css';
import "./css/site.css";

import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { LocalizationProvider } from "@progress/kendo-react-intl";

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import App from './components/mainPages/App';
import store from "./store";

const language = "default";
const rootElement = document.getElementById('root');

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <LocalizationProvider language={language}>
                <App />
            </LocalizationProvider>
        </BrowserRouter>
    </Provider>,
  rootElement);

// If you want your app to work offline and load faster, you can change
//// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
