// Headers
export const APP_HEADER = "ModuleHeader_App";
export const APP_TAB_BROWSER_HEADER = "ModuleHeader_App_Tab_Browser";
export const DASHBOARD_HEADER = "DashboardView_Header";
export const ACCOUNTS_HEADER = "ModuleHeader_Accounts";
export const PORTFOLIO_HEADER = "ModuleHeader_Portfolio";
export const EQUITY_HEADER = "ModuleHeader_Equity";
export const PAYOUTS_HEADER = "ModuleHeader_Payouts";
export const IDEAS_HEADER = "ModuleHeader_Ideas";
export const HELP_HEADER = "ModuleHeader_Help";
export const HEADER_PROFIT_FIXER = "Header_ProfitFixer";
export const HEADER_OTHER_INSTRUMENTS = "Header_PageOtherInstruments";
export const HEADER_RESTORE_POSITIONS = "Header_RestorePositions";
export const HEADER_CORRELATION = "ModuleHeader_Correlation";
export const HEADER_BIND_DEVICE = "ModuleHeader_BindDevice";

// Authorization
export const LOGIN = "Authorization_Login";
export const LOGOUT = "Authorization_Logout";
export const PASSWORD = "Authorization_Password";
export const PASSWORD_CONFIRM = "Authorization_PasswordConfirm";
export const REGISTER = "Authorization_Register";
export const SIGN_IN = "Authorization_SignIn";
export const OR_REGISTER = "Authorization_OrRegister";
export const OR_SIGN_IN = "Authorization_OrSignIn";
export const OR_DEMO = "Authorization_OrDemo";
export const OR = "Authorization_Or";
export const DEMO_ACCOUNT_LOADING = "Authorization_DemoAccountLoading";

// Authorization Error
export const ERROR_EMPTY_USER = "Authorization_Error_EmptyUser";
export const ERROR_EMPTY_PASSWORD = "Authorization_Error_EmptyPassword";
export const ERROR_CONFIRM = "Authorization_Error_Confirm";

// Portfolio
export const SHARE = "Portfolio_Share";
export const DAY_PRICE_CHANGE = "Portfolio_DayPriceChange";
export const EXECUTION_OPEN_QUANTITY = "Portfolio_Execution_OpenQuantity";
export const PORTFOLIO_MARKET_VALUE = "PortfolioView_MarketValue";
export const PORTFOLIO_INVESTED_VALUE = "Portfolio_InvestedValue";
export const PORTFOLIO_TOTAL_PL_PERCENT = "Portfolio_TotalPlPercent";
export const PORTFOLIO_EPIRATION_DATE = "Portfolio_DateExpiration";
export const PORTFOLIO_PIECES = "Portfolio_Pieces";

// PortfolioExecutions
export const AVG_POSITION_PRICE = "Portfolio_Executions_AvgPositionPrice";

// Common
export const SYMBOL = "Common_Symbol";
export const NAME = "Common_Name";
export const QUANTITY = "Common_Quantity";
export const AVERAGE_PRICE = "Common_AveragePrice";
export const LAST_PRICE = "Common_LastPrice";
export const REALIZED_PL = "Common_RealizedPl";
export const UNREALIZED_PL = "Common_UnrealizedPl";
export const TOTAL = "Common_Total";
export const TOTAL_PL = "Common_TotalPl";
export const PL = "Common_Pl";
export const TIME = "Common_Time";
export const TYPE = "Common_Type";
export const AMOUNT = "Common_Amount";
export const ACTION = "Common_Action";
export const PRICE = "Common_Price";
export const EXECUTIONS = "Common_Executions";
export const PAYOUTS = "Common_Payouts";
export const ACCOUNT = "Common_Account";
export const BROKER = "Common_Broker";
export const HOLDER = "Common_Holder";
export const LOADER = "Common_Loading";
export const CONFIRM = "Common_MessageDialogConfirm";
export const YES = "Common_Yes";
export const NO = "Common_No";
export const LOAD_STATEMENT_HELP = "LoadStatementsHelp";


// Common // Date parts
export const DATE_PARTS_YEAR = "Common_DateParts_Year";
export const DATE_PARTS_WEEK = "Common_DateParts_Week";

// Connector
export const CONNECTOR_STATUS = "Connector_Status";
export const BROKER_CONNECTOR = "Connector_BrokerConnector";
export const CONNECT = "Connector_Connect";
export const DISCONNECT = "Connector_Disconnect";
export const REMOVE_CONNECTOR = "Connector_RemoveConnector";
export const ADD_CONNECTOR = "Connector_AddConnector";

// Dashboard
export const GENERATE_DEMO_DATA = "DashboardView_GenerateDemo";
export const DASHBOARD_IMPORT_STATEMENTS = "DashboardView_ImportStatements";
export const DASHBOARD_NO_DATA_TEXT = "DashboardView_NoDataText";
export const DASHBOARD_INFOPANEL_BETA = "InfoPanel_Beta";
export const DASHBOARD_INFOPANEL_INVESTING_PERIOD = "InfoPanel_InvestingPeriod";
export const DASHBOARD_INFOPANEL_NET_ASSET_VALUE = "InfoPanel_NetAssetValue";
export const DASHBOARD_INFOPANEL_SHARP = "InfoPanel_Sharp";
export const DASHBOARD_INFOPANEL_TOTAL_PNL = "InfoPanel_TotalPnl";
export const DASHBOARD_INFOPANEL_VOLATILITY = "InfoPanel_Volatility";
export const DASHBOARD_PIE_CHART_HEADER_ASSETS = "PieChartsViewModel_PiesHeaders_Assets";
export const DASHBOARD_PIE_CHART_HEADER_BROKERS = "PieChartsViewModel_PiesHeaders_Brokers";
export const DASHBOARD_PIE_CHART_HEADER_COMPANIES = "PieChartsViewModel_PiesHeaders_Companies";
export const DASHBOARD_PIE_CHART_HEADER_HOLDERS = "PieChartsViewModel_PiesHeaders_Holders";
export const DASHBOARD_PIE_CHART_HEADER_INSTRUMENTS = "PieChartsViewModel_PiesHeaders_Instruments";
export const DASHBOARD_PIE_CHART_HEADER_SECTORS = "PieChartsViewModel_PiesHeaders_Sectors";
export const DASHBOARD_PIE_CHART_HEADER_CURRENCIES = "PieChartsViewModel_PiesHeaders_Currencies";
export const DASHBOARD_INFOPANEL_UPDATE_GLOBAL_STAT_DATA_TODAY_CHANGE = "StatisticInfo_BuildModels_Today_change";
export const DASHBOARD_IMPORT_STATEMENT_HEADER = "Dashboard_ImportStatementHeader";
export const DASHBOARD_INDICATORS = "Dashboard_Indicators";


// Equity
export const EQUITY_TABLE_HEADER_PERIODS = "Equity_TableHeader_Periods"
export const EQUITY_TABLE_HEADER_INSTRUMENTS = "Equity_TableHeader_Instruments"
export const EQUITY_TABLE_HEADER_MONEY = "Equity_TableHeader_Money"
export const EQUITY_TABLE_COLUMN_DIVIDENS_AND_COUPONS = "Equity_TableColumn_DividendsAndCoupons";
export const EQUITY_REPORT_CUSTOM_PERIOD = "Equity_ReportCustomPeriod";
export const EQUITY_OPEN_CUSTOM_PERIOD = "Equity_OpenCustomPeriod";
export const EQUITY_DETAILS_VIEW_POSITION = "EquityDetailsView_PositionInfo";
export const EQUITY_DETAILS_MARKET_PRICE_AT_BEGIN = "EquityDetailsView_MarketPriceAtBegin";
export const EQUITY_DETAILS_MARKET_PRICE_AT_END = "EquityDetailsView_MarketPriceAtEnd";
export const EQUITY_DETAILS_AVG_PRICE_AT_END = "EquityDetailsView_AvgPriceAtEnd";
export const EQUITY_DETAILS_QTY_AT_END = "EquityDetailsView_QtyAtEnd";
export const EQUITY_ACCOUNT = "Equity_Account";
export const EQUITY_DETAILS_HEADER = "EquityDetailsViewModel_Header";

// Account

export const ACCOUNT_HEADER_STATEMENTS = "AccountHeader_Statements";
export const ACCOUNT_HEADER_FROM = "AccountHeader_From";
export const ACCOUNT_HEADER_TO = "AccountHeader_To";
export const ACCOUNT_HEADER_SOURCE = "AccountHeader_Source";
export const ACCOUNT_IMPORT_STATEMENT = "Account_ImportStatement";
export const ACCOUNT_IMPORT_STATEMENT_MOBILE = "Account_ImportStatementMobile";
export const ACCOUNT_CLEAR = "Account_Clear";
export const ACCOUNT_CLEAR_MOBILE = "Account_ClearMobile";
export const ACCOUNT_YOU_SURE_DELETE_ACCOUNTS = "Accounts_AreYouSureYouWantToDeleteAccounts";


//Ideas

export const IDEAS_SYMBOL = "Ideas_Symbol";
export const IDEAS_ADD = "Ideas_Add";
export const IDEAS_TITLE_SYMBOL = "Ideas_Title_Symbol";
export const IDEAS_TITLE_NAME = "Ideas_Title_Name";
export const IDEAS_DISTANCE_TO_TARGET = "Ideas_DistanceToTarget";
export const IDEAS_DISTANCE_TO_STOP = "Ideas_DistanceToStop";
export const IDEAS_DAYS_TILL_TARGET = "Ideas_DaysTillTarget";
export const IDEAS_DESCRIPTION = "Ideas_Description";
export const IDEAS_CREATION_TIME = "Ideas_CreationTime";
export const IDEAS_TARGET_TIME = "Ideas_TargetTime";
export const IDEAS_INSTRUMENT = "Ideas_Instrument";
export const IDEAS_PRICE_AT_CREATION_TIME = "Ideas_PriceAtCreationTime";
export const IDEAS_CURRENT_PRICE = "Ideas_CurrentPrice";
export const IDEAS_PARAMETERS = "Ideas_Parameters";
export const IDEAS_STOP_PRICE = "Ideas_StopPrice";
export const IDEAS_TARGET_PRICE = "Ideas_TargetPrice";
export const IDEAS_PERIOD_NAME = "Ideas_PeriodName";
export const IDEAS_PERIODS_COUNT = "Ideas_PeriodsCount";
export const IDEAS_NOTES = "Ideas_Notes";
export const IDEAS_TEXT = "Ideas_Text";
export const IDEAS_TIME = "Ideas_Time";
export const IDEAS_NEW_NOTE_ADD_TIP = "Ideas_NewNoteAddTip";
export const IDEAS_UPDATE = "Ideas_Update";
export const IDEAS_DEACTIVATE = "Ideas_Deactivate";

// Errors Service
export const ERRORS_SERVICE_TOTAL_ERRORS_1 = "ErrorService_TotalErrors_1";
export const ERRORS_SERVICE_TOTAL_ERRORS_2 = "ErrorService_TotalErrors_2";
export const ERRORS_SERVICE_TOTAL_ERRORS_3 = "ErrorService_TotalErrors_3";

//PAYOUT

export const DAYS_TILL_OFFER = "PayoutsView_DaysTillOffer";
export const OFFER_DATE = "Payout_OfferDate";
export const NOMINAL_PRICE = "Payout_NominalPrice";
export const UPCOMING_OFFERS = "Header_UpcomingOffers";

//ProfitFixer
export const TABLE_HEADER_ACTIONS = "ProfitFixer_HeaderBtnActions";
export const CLOSE_BUTTON_CONTENT = "ProfitFixerHeader_CloseButtonContent";

//RestorePositions
export const HEADER_EXECUTION_TIME = "RestorePositions_HeaderExecutionTime";
export const HEADER_REMAIN = "RestorePositions_HeaderRemain";
export const HEADER_EXECUTION_PRICE = "RestorePositions_HeaderExecutionPrice";
export const HEADER_PRICE_CHANGE = "RestorePositions_HeaderPriceChange";
export const HEADER_REALIZED_LOSS = "RestorePositions_HeaderRealizedLoss";
export const HEADER_UNCOVERED_LOSS = "RestorePositions_HeaderUncoveredLoss";

export const INFO_CLOSE_POSITION = "Info_ClosePosition";
export const BTN_BUY = "ButtonContent_Buy";