import {
    LOAD_USAGE, LOAD_USAGE_SUCCESS,

    LOAD_USER_USAGE, LOAD_USER_USAGE_SUCCESS,
    LOAD_FUNC_USAGE, LOAD_FUNC_USAGE_SUCCESS
} from "./actions";

// USAGE
export const loadUsage = () => ({
    type: LOAD_USAGE,
})
export const loadUsageSuccess = (data) => ({
    type: LOAD_USAGE_SUCCESS,
    payload: data
})

// USER USAGE
export const loadUserUsage = (id) => ({
    type: LOAD_USER_USAGE,
    payload: id
})
export const loadUserUsageSuccess = (data) => ({
    type: LOAD_USER_USAGE_SUCCESS,
    payload: data
})

// FUNC USAGE
export const loadFuncUsage = (func) => ({
    type: LOAD_FUNC_USAGE,
    payload: func
})
export const loadFuncUsageSuccess = (data) => ({
    type: LOAD_FUNC_USAGE_SUCCESS,
    payload: data
})