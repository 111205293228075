import { useDispatch, useSelector } from "react-redux";

import { useLocalization, useInternationalization } from "@progress/kendo-react-intl";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";

import { EQUITY_TABLE_HEADER_INSTRUMENTS, EQUITY_TABLE_HEADER_MONEY, EQUITY_TABLE_HEADER_PERIODS, DATE_PARTS_WEEK, EQUITY_DETAILS_HEADER } from "../../../../infrastructure/localizationKeys";

import MoneyTab from "./MoneyTab"
import PeriodsTab from "./PeriodsTab";
import InstrumentsTab from "./InstrumentTab";
import AddCustomPeriodTab from "./AddCustomPeriodTab";
import CustomPeriodTab from "./CustomPeriodTab";
import InstrumentDetailsTab from "./InstrumentDetailsTab";

import { PeriodTypes } from "../../../../infrastructure/enums/periodTypes";

import { equityRemovePeriod, equityTabSelect, equityRemoveInstrumentDetails } from "../../../../store/reducers/equityReducer/creators";

const FooterTabs = (props) => {
    const intl = useInternationalization();
    const localization = useLocalization();
    const dispatch = useDispatch();

    const instruments = useSelector(state => state.equity.instruments);
    const money = useSelector(state => state.equity.money);
    const tabSelected = useSelector(state => state.equity.tabSelected);
    
    const listTabs = useSelector(state => state.equity.listTab);

    const handleSelect = (e) => {
        dispatch(equityTabSelect(e.selected));
    };

    const getPeriodHeader = (period) => {
        const start = new Date(period.start);
        const end = new Date(period.end);

        const dayFormat = (date) => intl.formatDate(date, "dd.MM.yyyy");

        const shortDayFormat = (date) => intl.formatDate(date, "dd.MM");

        switch (period.periodType) {
            case PeriodTypes.YearTotal:
                return start.getFullYear();
            case PeriodTypes.Month:
                return intl.formatDate(start, "MMM yyyy");
            case PeriodTypes.WeekTotal:
                return `${localization.toLanguageString(DATE_PARTS_WEEK)} ${period.week} (${shortDayFormat(start)}-${shortDayFormat(end)}) ${start.getFullYear()}`;
            case PeriodTypes.Day:
                return dayFormat(start);
            case PeriodTypes.Custom:
                return `${dayFormat(start)} - ${dayFormat(end)}`;
            default:
                return ""
        }
    }

    const PeriodTitle = ({ period }) => {
        return (
            <>
                {getPeriodHeader(period)}
                <span className="k-spacer" />
                <span
                    className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base k-icon-button"
                    onClick={() => { dispatch(equityRemovePeriod(period)) }}>
                    <span className="k-icon k-i-x" />
                </span>
            </>
        )
    }

    const DetailsTitle = ({ data }) => {
        return (
            <>
                {localization.toLanguageString(EQUITY_DETAILS_HEADER)} {data.instrument.Symbol}
                <span className="k-spacer" />
                <span
                    className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base k-icon-button"
                    onClick={() => { dispatch(equityRemoveInstrumentDetails(data)) }}>
                    <span className="k-icon k-i-x" />
                </span>
            </>
        )
    }

    let tabHeight = (props.height.replace("px", '') - 81) + "px";

    return (
        <TabStrip selected={tabSelected} onSelect={handleSelect} className="k-mt-3">

            <TabStripTab title={localization.toLanguageString(EQUITY_TABLE_HEADER_PERIODS)}>
                <PeriodsTab height={tabHeight} />
            </TabStripTab>

            <TabStripTab title={localization.toLanguageString(EQUITY_TABLE_HEADER_INSTRUMENTS)}>
                <InstrumentsTab data={instruments} height={tabHeight} />
            </TabStripTab>

            <TabStripTab title={localization.toLanguageString(EQUITY_TABLE_HEADER_MONEY)}>
                <MoneyTab data={money} height={tabHeight} />
            </TabStripTab>
            
            {listTabs.map((tab, i) => {
                if (tab.start != undefined)
                    return (
                        < TabStripTab title={< PeriodTitle period={tab} />} key={`${tab.start}-${tab.end}-${tab.periodType}`}>
                            <CustomPeriodTab height={tabHeight} data={tab} />
                        </TabStripTab>)
                else if (tab.instrument != undefined)
                    return (
                        <TabStripTab title={<DetailsTitle data={tab} />} key={`${tab.instrument.Symbol}-${i}`}>
                            <InstrumentDetailsTab height={tabHeight}
                                instrument={tab.instrument}
                                executions={tab.executions}
                                fundMovements={tab.fundMovements}
                                fundMovementTotals={tab.fundMovementTotals} />
                        </TabStripTab>
                    )

            })}

            <TabStripTab title="+">
                <AddCustomPeriodTab height={tabHeight} />
            </TabStripTab>

        </TabStrip>
    )
}

export default FooterTabs;