import { Checkbox } from "@progress/kendo-react-inputs";

const CheckboxField = (props) => {

    const handleChange = (event) => {
        props.onPropertyEdit(props.propertyName, event.target.value);
    }
    const style = {
        width: props.width
    }

    return (
        <div style={style}>
            <Checkbox
                disabled={props.disabled}
                onChange={handleChange}
                value={props.value}
            />
        </div>
    )
}

export default CheckboxField;