import { useState } from "react";
import { useSelector } from "react-redux";
import { ButtonGroup, Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Dialog } from "@progress/kendo-react-dialogs";

import ObjectEditor from "../../views/ObjectEditor";

const EquityProperties = (props) => {
    const [data, setData] = useState(props.data.map(x => ({ ...x })));

    const dataButtons = useSelector(state => state.enums[props.data[0].propertyType]);
    const dispayUnit = useSelector(state => state.enums[props.data[1].propertyType]);

    const [selectButtonDisplayMode, setSelectButtonDisplayMode] = useState(props.data[0].value);
    const [selectButtonDisplayUnit, setSelectButtonDisplayUnit] = useState(props.data[1].value);

    const [visibleModal, setVisibleModal] = useState(false);

    const onPropertyEdit = (name, value) => {
        const result = data.map(x => x.propertyName === name ? { ...x, value } : { ...x });
        setData(result);
        props.onUpdate(result);
    }

    const updateHandler = (result) => {
        setData(result);
        props.onUpdate(result);
    }

    const handleClickDisplayMode = (value) => {
        setSelectButtonDisplayMode(value);
        onPropertyEdit(props.data[0].propertyName, value);
    };

    const handleClickDisplayUnit = (value) => {
        setSelectButtonDisplayUnit(value);
        onPropertyEdit(props.data[1].propertyName, value);
    };

    const styleButton = {
        height: "30px",
        fontSize: "90%"
    }

    const handleDrownListChange = (event) => {
        onPropertyEdit(props.data[3].propertyName, event.target.value);
    }

    const style = {
        width: "auto",
        height: "30px",
        fontSize: "90%",
        marginLeft: "5px"
    }

    const styleLine = {
        display: "inline-block",
        width: "380px"
    }
    
    const DialogModal = () => {
        return (visibleModal &&
            <Dialog title=" " modal={true} onClose={toggleDialog} resizable={true} width={window.innerWidth - 10}>
                <ObjectEditor data={props.data} onUpdate={updateHandler} orientation="vertical" />
            </Dialog>
        );
    }

    const toggleDialog = () => {
        setVisibleModal(!visibleModal);
    };

    const getName = (name) => {
        switch (name) {
            case "Net asset value":
                return "Assets";
            case "Сumulative return":
                return "Сumulative";
            case "Стоимость портфеля":
                return "Активы";
            case "Доход нарастающим итогом":
                return "Доход";
            default:
                " ";
        }
    }

    const nameBtnAsset = getName(dataButtons[0].display);
    const nameBtnСumulative = getName(dataButtons[1].display);
    return (dataButtons != undefined && dispayUnit != undefined ?
        <div className="equity-properties">
            <div style={styleLine}>
                <ButtonGroup>
                    <Button
                        togglable={true}
                        selected={selectButtonDisplayMode === dataButtons[0].key}
                        onClick={handleClickDisplayMode.bind(undefined, dataButtons[0].key)}
                        style={styleButton}
                    >
                        {nameBtnAsset}
                    </Button>
                    <Button
                        togglable={true}
                        selected={selectButtonDisplayMode === dataButtons[1].key}
                        onClick={handleClickDisplayMode.bind(undefined, dataButtons[1].key)}
                        style={styleButton}
                    >
                        {nameBtnСumulative}
                    </Button>
                </ButtonGroup>

                <ButtonGroup>
                    <Button
                        togglable={true}
                        selected={selectButtonDisplayUnit === dispayUnit[0].key}
                        onClick={handleClickDisplayUnit.bind(undefined, dispayUnit[0].key)}
                        style={{
                            height: "30px",
                            fontSize: "90%",
                            marginLeft: "5px"
                        }}
                    >₽</Button>
                    <Button
                        togglable={true}
                        selected={selectButtonDisplayUnit === dispayUnit[1].key}
                        onClick={handleClickDisplayUnit.bind(undefined, dispayUnit[1].key)}
                        style={styleButton}
                    >%</Button>
                </ButtonGroup>

                <DropDownList
                    onChange={handleDrownListChange}
                    data={props.data[3].collection}
                    value={props.data[3].value}
                    style={style}
                />

                <Button style={style} icon="k-icon k-i-gear" onClick={toggleDialog}></Button>
                <DialogModal />
            </div>
        </div> : <div/>

    );
}

export default EquityProperties;