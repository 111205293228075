import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import { useLocalization } from "@progress/kendo-react-intl";
import { Button } from '@progress/kendo-react-buttons';

import { AppBar, AppBarSection, AppBarSpacer, Drawer, DrawerContent, Card, CardBody } from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import { isMobile } from 'react-device-detect';

import Notification from "./Notification";

import { logout, changeExpand } from "../../../store/reducers/generalReducer/generalCreators"


import {
    APP_HEADER, LOGOUT, APP_TAB_BROWSER_HEADER, HELP_HEADER,
    ACCOUNTS_HEADER, DASHBOARD_HEADER, EQUITY_HEADER, PORTFOLIO_HEADER,
    PAYOUTS_HEADER, HEADER_PROFIT_FIXER, HEADER_OTHER_INSTRUMENTS, HEADER_RESTORE_POSITIONS, HEADER_CORRELATION,
    HEADER_BIND_DEVICE/* IDEAS_HEADER*/
} from "../../../infrastructure/localizationKeys";

function MainWindow() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const localization = useLocalization();
    const userName = useSelector(state => state.login.name);
    const role = useSelector(state => state.login.role);

    document.title = localization.toLanguageString(APP_TAB_BROWSER_HEADER);

    const isAdmin = role === "Admin";

    const items = isAdmin
        ? [
            {
                text: 'Users',
                icon: 'k-i-user',
                selected: false,
                route: '/users'
            },
            {
                text: 'Usage',
                icon: 'k-i-connector',
                selected: true,
                route: '/usage'
            }
        ]
        : [
            {
                text: localization.toLanguageString(DASHBOARD_HEADER),
                icon: 'k-i-grid',
                selected: false,
                route: '/dashboard'
            },
            {
                text: localization.toLanguageString(ACCOUNTS_HEADER),
                icon: 'k-i-paste-plain-text',
                selected: false,
                route: '/accounts'
            },
            {
                text: localization.toLanguageString(PORTFOLIO_HEADER),
                icon: 'k-i-module-manager',
                selected: false,
                route: '/portfolio'
            },
            {
                text: localization.toLanguageString(EQUITY_HEADER),
                icon: 'k-i-clock',
                selected: true,
                route: '/equity'
            },
            {
                text: localization.toLanguageString(PAYOUTS_HEADER),
                icon: 'k-i-currency',
                selected: false,
                route: '/payouts'
            },
            {
                separator: true,
            },
            {
                text: localization.toLanguageString(HEADER_PROFIT_FIXER),
                icon: 'k-i-check',
                selected: false,
                route: '/profitfixer'
            },
            {
                text: localization.toLanguageString(HEADER_RESTORE_POSITIONS),
                icon: 'k-i-reset',
                selected: false,
                route: '/restorepositions'
            },
            {
                text: localization.toLanguageString(HEADER_CORRELATION),
                icon: 'k-i-table-cell',
                selected: false,
                route: '/correlation'
            },
            {
                text: localization.toLanguageString(HEADER_OTHER_INSTRUMENTS),
                icon: 'k-i-plus',
                selected: false,
                route: '/otherinstruments'
            },
            {
                separator: true,
            },
            {
                text: localization.toLanguageString(HELP_HEADER),
                icon: 'k-i-help',
                selected: false,
                route: '/help'
            },
            //{
            //    text: localization.toLanguageString(IDEAS_HEADER),
            //    icon: 'k-i-question',
            //    selected: false,
            //    route: '/ideas'
            //},
            //{
            //    text: "DEBUG",
            //    icon: 'k-i-wrench',
            //    selected: false,
            //    route: '/debug'
            //}
        ]

    if (!isMobile) {
        var bindDevice = {

            text: localization.toLanguageString(HEADER_BIND_DEVICE),
            icon: 'k-i-link-vertical',
            selected: false,
            route: '/binddevice'
        }
        var separator = {
            separator: true,
        };
        items.push(separator);
        items.push(bindDevice);
    }
    if (isMobile) {

        var separator = {
            separator: true,
        };

        var exit =
        {
            text: localization.toLanguageString(LOGOUT),
            icon: 'k-i-logout',
            selected: false
        };
        items.push(separator);
        items.push(exit);
    }

    useEffect(() => {
        if (location.pathname === "/") {
            navigate("/dashboard");
        }
    });

    const [expanded, setExpanded] = isMobile ? useState(false) : useState(true);

    const [title, setTitle] = useState(items.filter(x => x.route === location.pathname)[0]?.text);

    const [isSmallerScreen, setSmallerScreen] = isMobile ? useState(true) : useState(window.innerWidth < 768);

    const [selected, setSelected] = useState(location.pathname);

    const [height, setHeight] = useState(window.innerHeight);

    const logoutHandle = () => dispatch(logout());



    const handleClick = () => {
        setExpanded(!expanded);

        dispatch(changeExpand());
    }

    const resizeWindow = () => {
        setSmallerScreen(isMobile ? true : window.innerWidth < 1024)
        setHeight(window.innerHeight);
    }


    const handleSelect = (e) => {
        if (isSmallerScreen)
            setExpanded(!expanded);
        if (e.itemTarget.props.route == undefined) {
            logoutHandle();
            return;
        }
        setSelected(e.itemTarget.props.route)
        navigate(e.itemTarget.props.route);
        setTitle(e.itemTarget.props.text);
        if (isSmallerScreen)
            setExpanded(!expanded);
    }

    const classNamePage = isMobile ? "page-mobile" : "page";
    const classNameCard = isMobile ? "card-mobile" : "card";
    const classNameMainPage = isMobile ? "main-page-mobile" : "main-page";
    let pageHeight = (height - 55) + "px";
    return (
        <>
            {window.addEventListener('resize', resizeWindow, false)}
            <div className={classNameMainPage} style={{ height: height }}>
                <AppBar style={{ boxShadow: "0 0 10px rgba(0,0,0,0.5)", height: "55px" }} >
                    <AppBarSection>
                        <div className="menu-button">
                            <span className={'k-icon k-i-menu'} onClick={handleClick} style={{ marginLeft: 9 }} />
                        </div>
                        {!isMobile &&
                            <h1 className="nav-title">
                                {localization.toLanguageString(APP_HEADER)}
                            </h1>
                        }
                        {!isMobile && <h3>{"-"}</h3>}
                        <h1 className="nav-title-page">
                            {title}
                        </h1>
                    </AppBarSection>

                    <AppBarSpacer />

                    <AppBarSection>
                        <Label className="k-mx-3">{userName}</Label>
                        {!isMobile && <Button onClick={logoutHandle} fillMode="outline">
                            {localization.toLanguageString(LOGOUT)}
                        </Button>}
                    </AppBarSection>


                </AppBar>

                <Drawer
                    expanded={expanded}
                    animation={{ duration: 100 }}

                    items={items.map((data) => ({
                        ...data,
                        selected: data.route === selected
                    }))
                    }
                    position='start'
                    mode={isSmallerScreen ? 'overlay' : 'push'}
                    mini={isSmallerScreen ? false : true}
                    onSelect={handleSelect}
                >
                    <DrawerContent>
                        <div className={classNamePage} style={{ height: pageHeight }}>
                            {isMobile ? <Outlet /> :
                                <Card className={classNameCard}>
                                    <CardBody>
                                        <Outlet />
                                    </CardBody>
                                </Card>
                            }
                        </div>
                    </DrawerContent>
                </Drawer>
                <Notification />
            </div>
        </>
    );
}

export default MainWindow;