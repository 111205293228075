import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useEffect } from "react";

import { loadUserUsage } from "../../../../store/reducers/usageReducer/creators";

import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";

import { DateTimeCell } from "../../../views/DataGridCells";

const User = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const id = params.id;
    const userUsage = useSelector(state => state.usage.userUsage);

    useEffect(() => {
        if (id != userUsage.id) {
            dispatch(loadUserUsage(id));
        }
    })

    const data = id == userUsage.id ? userUsage.stat : [];

    return (
        <Grid data={data} className="admin-table">
            <Column field="user.name" title="User" />
            <Column field="date" title="Date" cell={DateTimeCell} />
            <Column field="function" title="Function" />
            <Column field="arguments" title="Arguments" />
        </Grid>
    )
}

export default User;