export const LOAD_USERS = "LOAD_USERS";
export const LOAD_USERS_SUCCESS = "LOAD_USERS_SUCCESS";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";

export const EDIT_USER_ENTER = "EDIT_USER_ENTER";
export const EDIT_USER = "EDIT_USER";
export const CANCEL_EDIT_USER = "CANCEL_EDIT_USER";