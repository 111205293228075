import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocalization } from "@progress/kendo-react-intl";
import { Splitter } from "@progress/kendo-react-layout";

import PayoutsTable from "./PayoutsTable";
import { LOADER } from "../../../infrastructure/localizationKeys";
import { payoutsLoad } from "../../../store/reducers/payoutsReducer/creators";
import LoaderComponent from "../../views/LoaderComponent";
import BondsTable from "./BondsTable";

const PayoutsD = () => {
    const dispatch = useDispatch();
    const localization = useLocalization();
    const loading = useSelector(state => state.payouts.loading);
    const loaded = useSelector(state => state.payouts.loaded);

    useEffect(() => {
        if (!loading && !loaded)
            dispatch(payoutsLoad())
    })

    const [panes, setPanes] = useState([{ size: window.innerHeight*0.6+"px" }, {}]);

    const [height, setHeight] = useState(window.innerHeight);

    let heightPanelBottom = (height - panes[0].size.replace("px", '') - 65) + "px";

    let heightPatelTop = height - heightPanelBottom.replace("px", '') + "px";

    const onChange = (event) => {
        setPanes(event.newState);
    };

    const resizeWindow = () => {
        setHeight(window.innerHeight);
    }

    return loading ?
        <LoaderComponent description={localization.toLanguageString(LOADER)} />
        :
        <>
            {window.addEventListener('resize', resizeWindow, false)}
            <Splitter panes={panes} onChange={onChange} orientation={"vertical"}>
                <PayoutsTable height={heightPatelTop}/>
                <BondsTable height={heightPanelBottom} />
            </Splitter>
        </>;
}

export default PayoutsD;