import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";

import { correlationPropertiesUpdate, tableSort } from "../../../store/reducers/correlationReducer/creators";
import ObjectEditor from "../../views/ObjectEditor";
import { getPositiveNegativeColor, getBlackWhiteColor } from "../../../infrastructure/gradientPercentToColor";


const CorrelationTable = () => {
    const dispatch = useDispatch();

    const data = useSelector(state => state.correlation.correlations);

    const properties = useSelector(state => state.correlation.properties);

    const expanded = useSelector(state => state.general.expandMenu);

    const columns = data
        .map(x => x.header)
        .sort((a, b) => a > b ? 1 : -1);

    const CustomCell = (props) => {
        const data = props.dataItem.listInstrumentCoeffCorrelation.find(x => x.name === props.field)?.value;
        let style = {
            background: getPositiveNegativeColor(data),
            color: getBlackWhiteColor(data),
            textAlign: 'right'
        }
        if (data < properties[3]?.value || data > properties[4].value || data === undefined)
            style = {
                background: "transparent",
                color: "black",
                textAlign: 'right'
            }
        return (
            <td style={style}>
                {data}
            </td>
        )
    }


    const [width, setWidth] = useState(window.innerWidth);

    const widthTable = expanded ? width - 320 + "px" : width - 130 + "px";

    const resizeWindow = () => {
        setWidth(window.innerWidth);
    }

    const styleTable = {
        width: widthTable
    }

    const handleUpdate = (properties) => {
        dispatch(correlationPropertiesUpdate(properties))
        setSort([]);
    }

    const initialSort = [
        {
            field: "header",
            dir: "asc"
        }
    ];

    const [sort, setSort] = useState(initialSort);

    const customSort = (props) => {
        setSort(props.sort);
        dispatch(tableSort(props.sort[0]));
    }

    return (
        <>
            {window.addEventListener('resize', resizeWindow, false)}
            <ObjectEditor data={properties} onUpdate={handleUpdate} orientation="horizontal" step={0.1} minvalue={-1} maxvalue={1} />
            <Grid
                data={data}
                className="table-correlation"
                style={styleTable}
                sortable={true}
                sort={sort}
                onSortChange={(e) => {
                    customSort(e);
                }}>
                <Column
                    field="header"
                    title={" "}
                    width={60}
                    locked={true} />
                {columns.map((x, i) => {
                    return (
                        <Column
                            key={i}
                            field={x}
                            title={x}
                            width={90}
                            cell={CustomCell} />
                    )
                })}
            </Grid>
        </>
    );
}
export default CorrelationTable;