import { isMobile } from 'react-device-detect';

import ProfitFixerD from "./ProfitFixerD";
import ProfitFixerM from "./ProfitFixerM";

const ProfitFixer = () => {
    return (
        isMobile ? <ProfitFixerM /> : <ProfitFixerD />
    );
}

export default ProfitFixer;