import { fork, all, takeEvery } from 'redux-saga/effects';
import { ACCOUNTS_LOAD_DATA, /*ACCOUNTS_CREATE_CONNECTOR, ACCOUNTS_REMOVE_CONNECTOR, ACCOUNTS_UPDATE_CONNECTOR, ACCOUNTS_CONNECTOR_CONNECT, ACCOUNTS_CONNECTOR_DISCONNECT,*/ CLEAR_ALL_DATA } from "../../reducers/accountsReducer/actions"
import {
    loadAccountsError, loadAccountsSuccess,
    /*createConnectorSuccess, removeConnectorSuccess, updateConnectorSuccess,
    connectorStatusChange*/
} from "../../reducers/accountsReducer/creators";

import { clearData } from "../../reducers/generalReducer/generalCreators"
import { requset } from "../infrastructure";

const baseUrl = "/api/accounts";
//const connectorUrl = (id) => `${baseUrl}/${id}/connector`;
//const connectorConnectUrl = (id) => `${connectorUrl(id)}/connect/`;
//const connectorDisconnectUrl = (id) => `${connectorUrl(id)}/disconnect/`;

// WORKER SAGAS
function* load() {
    yield requset(baseUrl, "GET", loadAccountsSuccess, loadAccountsError);
}
//function* createConnector( { payload } ) {
//    yield requset(connectorUrl(payload.data), "POST", createConnectorSuccess);
//}
//function* removeConnector({ payload }) {
//    yield requset(connectorUrl(payload.data), "DELETE", removeConnectorSuccess);
//}
//function* updateConnector({ payload }) {
//    yield requset(connectorUrl(payload.id), "PUT", updateConnectorSuccess, null, payload.data);
//}
//function* connect({ payload }) {
//    yield requset(connectorConnectUrl(payload.data), "POST", connectorStatusChange);
//}
//function* disconnect({ payload }) {
//    yield requset(connectorDisconnectUrl(payload.data), "POST", connectorStatusChange);
//}

function* clearAll() {
    yield requset(baseUrl, "DELETE", clearData);
}

// WATCHER SAGAS
//function* createConnectorSaga() {
//    yield takeEvery(ACCOUNTS_CREATE_CONNECTOR, createConnector);
//}
//function* removeConnectorSaga() {
//    yield takeEvery(ACCOUNTS_REMOVE_CONNECTOR, removeConnector);
//}
//function* updateConnectorSaga() {
//    yield takeEvery(ACCOUNTS_UPDATE_CONNECTOR, updateConnector);
//}
function* loadSaga() {
    yield takeEvery(ACCOUNTS_LOAD_DATA, load);
}
//function* connectorConnectSaga() {
//    yield takeEvery(ACCOUNTS_CONNECTOR_CONNECT, connect);
//}
//function* connectorDisconnectSaga() {
//    yield takeEvery(ACCOUNTS_CONNECTOR_DISCONNECT, disconnect);
//}

function* clearAllSaga() {
    yield takeEvery(CLEAR_ALL_DATA, clearAll);
}

// ROOT SAGA
export default function* accountsSaga() {
    yield all([
        fork(loadSaga),
        //fork(createConnectorSaga),
        //fork(removeConnectorSaga),
        //fork(updateConnectorSaga),
        //fork(connectorConnectSaga),
        //fork(connectorDisconnectSaga),
        fork(clearAllSaga),
    ]);
}