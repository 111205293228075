import {
    RESTORE_POSITIONS_LOAD_DATA,
    RESTORE_POSITIONS_PROPERTIES_UPDATE,
    RESTORE_POSITIONS_ERROR,
    RESTORE_POSITIONS_LOAD_DATA_SUCCESS,
    RESTORE_POSITIONS_UPDATE_COLLECTION_ADD,
    RESTORE_POSITIONS_UPDATE_COLLECTION_REMOVE,
    RESTORE_POSITIONS_UPDATE_EXECUTION
} from "./actions";
import { LOGOUT, CLEAR_ALL, UPDATE_ALL_DATA, UPDATE } from "../generalReducer/generalActions";

const initial = {
    loaded: false,
    loading: false,
    data: [],
    properties: [],
    update: []
}

const restorePositionsReducer = (state = initial, action) => {
    switch (action.type) {
        case RESTORE_POSITIONS_LOAD_DATA:
            return {
                ...state,
                loading: true
            }
        case RESTORE_POSITIONS_LOAD_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                ...action.payload
            }
        case RESTORE_POSITIONS_PROPERTIES_UPDATE:
            return {
                ...state,
                ...action.payload
            }
        case RESTORE_POSITIONS_ERROR:
            return {
                ...state,
                loaded: true,
                loading: false
            }
        case RESTORE_POSITIONS_UPDATE_COLLECTION_ADD:
            let find = state.data.find(p => p.executionTime === action.payload.executionTime);
            if (find === undefined) {
                return {
                    ...state,
                    data: [...state.data, action.payload]
                }
            } else {
                return { ...state }
            }
        case RESTORE_POSITIONS_UPDATE_COLLECTION_REMOVE:
            let findOld = state.data.find(p => p.executionTime === action.payload.executionTime);
            return {
                ...state,
                data: state.data.filter(p => p != findOld)
            }
        case RESTORE_POSITIONS_UPDATE_EXECUTION:
            return {
                ...state,
                update: [...state.update, action.payload]
            }
        case UPDATE:
            const noUpdate = Object.keys(state.update).length === 0;
            if (noUpdate)
                return state;
            return{
                ...state,
                data: state.data.map(p => {
                    const find = state.update.find(x => x.executionTime === p.executionTime);
                    if (find != undefined) {
                        return find;
                    } else {
                        return p;
                    }
                }),
                update: []
            }
        case UPDATE_ALL_DATA:
            return {
                ...state,
                loading: false,
                loaded: false
            }
        case CLEAR_ALL:
            return initial;
        case LOGOUT:
            return initial;
        default:
            return state
    }
}

export default restorePositionsReducer;