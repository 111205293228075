import { select, apply, put, call } from 'redux-saga/effects';

export function* requset(url, type, successAction, errorAction = null, payload = null, authorize = true) {
    const params = {
        method: type,
        headers: {
            "Accept": "application/json, text/plain, */*",
            "Content-Type": "application/json",
        }
    }

    if (payload)
        params.body = JSON.stringify(payload);

    if (authorize) {
        const token = yield select(state => state.login.token);
        params.headers.Authorization = `Bearer ${token}`
    }

    try {
        const request = yield call(window.fetch, url, params);

        if (request.ok) {
            if (successAction) {
                const data = yield apply(request, request.json);
                yield put(successAction(data));
            }
        } else {
            const data = yield apply(request, request.text);
            if (errorAction)
                yield put(errorAction(data));
        }
    }
    catch
    {
        if (errorAction)
            yield put(errorAction("Something went wrong, please try again later"));
    }
}