export const DEBUG_ERROR = "DEBUG_ERROR";

import { fork, takeEvery } from 'redux-saga/effects';
import { requset } from "../infrastructure";

const baseUrl = "/api/debug";

// WORKER SAGAS
function* error({ payload }) {
    yield requset(baseUrl, payload, null);
}

// WATCHER SAGAS
function* errorSaga() {
    yield takeEvery(DEBUG_ERROR, error);
}

// ROOT SAGA
export default function* debugSaga() {
    yield fork(errorSaga);
}