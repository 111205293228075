import { DatePicker } from "@progress/kendo-react-dateinputs";

const DateTimeField = (props) => {

    const handleChange = (event) => {
        props.onPropertyEdit(props.propertyName, event.target.value.toISOString());
    }

    const style = {
        width: props.width
    }

    return (
        <div style={style}>
            <DatePicker
                defaultValue={new Date(props.value)}
                format="dd.MM.yyyy"
                onChange={handleChange}
            />
        </div>
    );
}

export default DateTimeField;