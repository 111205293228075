import { useLocalization } from "@progress/kendo-react-intl";
import { useSelector } from "react-redux";

import {
    DASHBOARD_INFOPANEL_INVESTING_PERIOD,
    DASHBOARD_INFOPANEL_NET_ASSET_VALUE,
    DASHBOARD_INFOPANEL_TOTAL_PNL,
    DASHBOARD_INFOPANEL_VOLATILITY,
    DASHBOARD_INFOPANEL_SHARP,
    DASHBOARD_INFOPANEL_BETA
} from "../../../infrastructure/localizationKeys";

const InfoPanel = () => {
    const localization = useLocalization();

    const info = useSelector(state => state.dashboard.info);

    const Panel = ({ header, value, description, link }) => {
        return (
            <div className="info-panel-border-mobile">
                <div className="info-panel-text-header-mobile">
                    <nobr>{header}
                        <sup>{link != null ? <a href={link} className="dashboard-link" target="_blank">?</a> : ""}</sup>
                    </nobr>
                </div>
                <div className="info-panel-text-value-mobile">
                    <nobr>{value}</nobr>
                </div>
                <div className="info-panel-text-description-mobile">
                    <nobr>{description}</nobr>
                </div>
            </div>
        )
    }

    const PanelChangeUpdate = ({ header, value }) => {
        const todayChange = useSelector(state => state.dashboard.todayChange);
        return (
            <div className="info-panel-border-mobile">
                <div className="info-panel-text-header-mobile">
                    <nobr>{header}</nobr>
                </div>
                <div className="info-panel-text-value-mobile">
                    <nobr>{value}</nobr>
                </div>
                <div className="info-panel-text-description-mobile">
                    <nobr>{todayChange}</nobr>
                </div>
            </div>
        )
    }

    return (
        <div className="info-panel-mobile">

            <PanelChangeUpdate header={localization.toLanguageString(DASHBOARD_INFOPANEL_NET_ASSET_VALUE)} value={info.netAssetValue} />

            <Panel header={localization.toLanguageString(DASHBOARD_INFOPANEL_TOTAL_PNL)} value={info.totalPlPercent} description={info.totalPlAmount} link={null} />

            <Panel header={localization.toLanguageString(DASHBOARD_INFOPANEL_INVESTING_PERIOD)} value={info.investingStartInfo} description={info.investingDays} link={null} />

            <Panel header={localization.toLanguageString(DASHBOARD_INFOPANEL_SHARP)} value={info.sharpRatio} description={info.sortino} link="https://investortools.ru/help/sharp" />

            <Panel header={localization.toLanguageString(DASHBOARD_INFOPANEL_VOLATILITY)} value={info.volatility} description={info.drawdown} link="https://investortools.ru/help/volatility" />

            <Panel header={localization.toLanguageString(DASHBOARD_INFOPANEL_BETA)} value={info.beta} description={info.betaLastMonth} link="https://investortools.ru/help/beta" />

        </div>
    )
}

export default InfoPanel;