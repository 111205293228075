export const ACCOUNTS_LOAD_DATA = "ACCOUNTS_LOAD_DATA";
export const ACCOUNTS_LOAD_DATA_SUCCESS = "ACCOUNTS_LOAD_DATA_SUCCESS";
export const ACCOUNTS_LOAD_DATA_ERROR = "ACCOUNTS_LOAD_DATA_ERROR";

//export const ACCOUNTS_CREATE_CONNECTOR = "ACCOUNTS_CREATE_CONNECTOR";
//export const ACCOUNTS_CREATE_CONNECTOR_SUCCESS = "ACCOUNTS_CREATE_CONNECTOR_SUCCESS";

//export const ACCOUNTS_REMOVE_CONNECTOR = "ACCOUNTS_REMOVE_CONNECTOR";
//export const ACCOUNTS_REMOVE_CONNECTOR_SUCCESS = "ACCOUNTS_REMOVE_CONNECTOR_SUCCESS";

//export const ACCOUNTS_UPDATE_CONNECTOR = "ACCOUNTS_UPDATE_CONNECTOR";
//export const ACCOUNTS_UPDATE_CONNECTOR_SUCCESS = "ACCOUNTS_UPDATE_CONNECTOR_SUCCESS";

//export const ACCOUNTS_CONNECTOR_CONNECT = "ACCOUNTS_CONNECTOR_CONNECT";
//export const ACCOUNTS_CONNECTOR_DISCONNECT = "ACCOUNTS_CONNECTOR_DISCONNECT";
//export const ACCOUNTS_CONNECTOR_STATUS_CHANGE = "ACCOUNTS_CONNECTOR_STATUS_CHANGE";

export const CLEAR_ALL_DATA = "CLEAR_ALL_DATA";
export const CLEAR_ALL_DATA_SUCCESS = "CLEAR_ALL_DATA_SUCCESS";