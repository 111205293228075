import { ENUM_LOAD_DATA_SUCCESS } from "./actions";

const initial = {
};

const enumReducer = (state = initial, action) => {
    switch (action.type) {
    case ENUM_LOAD_DATA_SUCCESS:
        const { type, data } = action.payload;
        return {
            ...state,
            [type]: data
        }
    default:
        return state
    }
};

export default enumReducer;