import { fork, all, takeEvery } from 'redux-saga/effects';
import { loadInfoSuccess, loadInfoError } from "../../../store/reducers/dashboardReducer/creators";
import { DASHBOARD_LOAD_DATA, DASHBOARD_GENERATE_DEMO_DATA, DASHBOARD_INFO_LINKS_HIDE } from "../../reducers/dashboardReducer/actions"
import { requset } from "../infrastructure";

const baseUrl = "/api/dashboard";
const generateUrl = `${baseUrl}/generate`;
const hideUrl = `${baseUrl}/hide`;

// WORKER SAGAS
function* load() {
    yield requset(baseUrl, "GET", loadInfoSuccess, loadInfoError);
}
function* hideLinks() {
    yield requset(hideUrl, "POST", null);
}
function* generateDemoData() {
    yield requset(generateUrl, "POST", loadInfoSuccess, loadInfoError)
}

// WATCHER SAGAS
function* generateDemoDataSaga() {
    yield takeEvery(DASHBOARD_GENERATE_DEMO_DATA, generateDemoData);
}
function* loadSaga() {
    yield takeEvery(DASHBOARD_LOAD_DATA, load);
}
function* hideLinksSaga() {
    yield takeEvery(DASHBOARD_INFO_LINKS_HIDE, hideLinks)
}

// ROOT SAGA
export default function* dashboardSaga() {
    yield all([
        fork(loadSaga),
        fork(generateDemoDataSaga),
        fork(hideLinksSaga)
    ]);
}