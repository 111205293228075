import {
    ACCOUNTS_LOAD_DATA, ACCOUNTS_LOAD_DATA_ERROR, ACCOUNTS_LOAD_DATA_SUCCESS,
    /*ACCOUNTS_CREATE_CONNECTOR, ACCOUNTS_CREATE_CONNECTOR_SUCCESS, */
    /*ACCOUNTS_REMOVE_CONNECTOR, ACCOUNTS_REMOVE_CONNECTOR_SUCCESS,*/
    //ACCOUNTS_UPDATE_CONNECTOR, ACCOUNTS_UPDATE_CONNECTOR_SUCCESS,
    //ACCOUNTS_CONNECTOR_CONNECT, ACCOUNTS_CONNECTOR_DISCONNECT, ACCOUNTS_CONNECTOR_STATUS_CHANGE,
    CLEAR_ALL_DATA
} from "./actions"

// Accounts Load

export const loadAccounts = () => ({
    type: ACCOUNTS_LOAD_DATA
});
export const loadAccountsSuccess = (data) => ({
    type: ACCOUNTS_LOAD_DATA_SUCCESS,
    payload: { data }
});
export const loadAccountsError = () => ({
    type: ACCOUNTS_LOAD_DATA_ERROR,
});

// Connector Create

//export const createConnector = (data) => ({
//    type: ACCOUNTS_CREATE_CONNECTOR,
//    payload: { data }
//});
//export const createConnectorSuccess = (data) => ({
//    type: ACCOUNTS_CREATE_CONNECTOR_SUCCESS,
//    payload: { data }
//});

// Connector Remove

//export const removeConnector = (data) => ({
//    type: ACCOUNTS_REMOVE_CONNECTOR,
//    payload: { data }
//});
//export const removeConnectorSuccess = (data) => ({
//    type: ACCOUNTS_REMOVE_CONNECTOR_SUCCESS,
//    payload: { data }
//});

// Connector Update

//export const updateConnector = (id, data) => ({
//    type: ACCOUNTS_UPDATE_CONNECTOR,
//    payload: { id, data }
//});
//export const updateConnectorSuccess = (data) => ({
//    type: ACCOUNTS_UPDATE_CONNECTOR_SUCCESS,
//    payload: { data }
//});

//// Connector Connect

//export const connectorConnect = (data) => ({
//    type: ACCOUNTS_CONNECTOR_CONNECT,
//    payload: { data }
//})

//// Connector Disconect

//export const connectorDisconnect = (data) => ({
//    type: ACCOUNTS_CONNECTOR_DISCONNECT,
//    payload: { data }
//})

//// Connector status change
//export const connectorStatusChange = (data) => ({
//    type: ACCOUNTS_CONNECTOR_STATUS_CHANGE,
//    payload: { data }
//})

// Delete all data
export const clearAllData = () => ({
    type: CLEAR_ALL_DATA
})

