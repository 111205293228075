import {
    ENUM_LOAD_DATA,
    ENUM_LOAD_DATA_SUCCESS,
} from "./actions";

export const enumLoad = (name) => ({
    type: ENUM_LOAD_DATA,
    payload: name
})

export const enumLoadSuccess = (data) => ({
    type: ENUM_LOAD_DATA_SUCCESS,
    payload: data
})