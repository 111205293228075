import { fork, all, takeEvery } from 'redux-saga/effects';
import { CORRELATION_LOAD_DATA, CORRELATION_PROPERTIES_UPDATE } from "../../reducers/correlationReducer/actions";
import { correlationLoadSuccess, correlationLoadError } from "../../reducers/correlationReducer/creators";
import { requset } from "../infrastructure";

const baseUrl = "/api/correlation";
const propertyUrl = `${baseUrl}/property`;

//WORKER SAGAS
function* load() {
    yield requset(baseUrl, "GET", correlationLoadSuccess, correlationLoadError);
}
function* update({ payload }) {
    yield requset(propertyUrl, "PUT", correlationLoadSuccess, null, payload);
}

//WATCHER SAGAS
function* loadCorrelationSaga() {
    yield takeEvery(CORRELATION_LOAD_DATA, load);
}
function* propertiesUpdateSaga() {
    yield takeEvery(CORRELATION_PROPERTIES_UPDATE, update);
}

//ROOT SAGA
export default function* correlationSaga() {
    yield all([
        fork(loadCorrelationSaga),
        fork(propertiesUpdateSaga)
    ]);
}