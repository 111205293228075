import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { NotificationGroup } from "@progress/kendo-react-notification";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useLocalization } from "@progress/kendo-react-intl";
import { Button } from '@progress/kendo-react-buttons';
import { Upload } from "@progress/kendo-react-upload";
import { loadInfo, dashboardGenerateDemoData, } from "../../../store/reducers/dashboardReducer/creators";
import { updateAllData } from "../../../store/reducers/generalReducer/generalCreators";

import {
    GENERATE_DEMO_DATA, DASHBOARD_NO_DATA_TEXT,
    DASHBOARD_IMPORT_STATEMENTS, DASHBOARD_IMPORT_STATEMENT_HEADER, LOADER,
    LOAD_STATEMENT_HELP
} from "../../../infrastructure/localizationKeys";

import LoaderComponent from "../../views/LoaderComponent";
import InfoPanel from "./InfoPanel";
import ProgressComponent from "../../views/ProgressComponent";
import LinksInfo from "./LinksInfo";
import PieCharts from "./PieCharts";
const saveUrl = "/api/dashboard/upload";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";

function DashboardD() {
    const localization = useLocalization();
    const dispatch = useDispatch();
    const token = useSelector(state => state.login.token);

    const processing = useSelector(state => state.dashboard.processing);
    const progress = useSelector(state => state.dashboard.progress);
    const loading = useSelector(state => state.dashboard.loading);
    const loaded = useSelector(state => state.dashboard.loaded);
    const dataExist = useSelector(state => state.dashboard.dataExist);
    const isVisibleLinksInfo = useSelector(state => state.dashboard.isVisibleLinksInfo);

    const error = useSelector(state => state.dashboard.error);
   
    const saveHeaders = { Authorization: "Bearer " + token }

    useEffect(() => {
        if (!loading && !loaded) {
            dispatch(loadInfo());
        }
    })

    const [visibleModal, setVisibleModal] = useState(false);

    const handleGenerateDemoData = () => {
        dispatch(dashboardGenerateDemoData());
    }

    const GenerateComponent = (
        <Button className="k-mr-3 k-mb-3" onClick={handleGenerateDemoData} disabled={progress}>
            {localization.toLanguageString(GENERATE_DEMO_DATA)}
        </Button>
    );

    const toggleDialog = () => {
        setVisibleModal(!visibleModal);
    };


    const UploadButton = (
        <Button className="k-mr-3 k-mb-3" onClick={toggleDialog} disabled={progress}>
            {localization.toLanguageString(DASHBOARD_IMPORT_STATEMENTS)}
        </Button>
    );

    const DialogModal = () => {

        const UploadComponent = (
            <>
                <div className="link-instruction-report">
                    <a href="https://investortools.ru/help/statements" target="_blank">{localization.toLanguageString(LOAD_STATEMENT_HELP)}</a>
                </div>
                <Upload
                    disabled={processing}
                    accept=".csv,application/vnd.ms-excel,.xml,.html"
                    autoUpload={false}
                    multiple={true}
                    batch={true}
                    onStatusChange={toggleDialog}
                    actionsLayout="start"
                    defaultFiles={[]}
                    saveUrl={saveUrl}
                    saveHeaders={saveHeaders}
                />
            </>
        );
        return (
            visibleModal &&
            (
                <Dialog title={localization.toLanguageString(DASHBOARD_IMPORT_STATEMENT_HEADER)} modal={true} onClose={toggleDialog} resizable={true} width={550}>
                    {UploadComponent}
                </Dialog>
            )
        );
    }

    const groupStyle = {
        bottom: 15,
        left: 18,
        zIndex: 1,
        fontWeight: "bold"
    };

    if (progress != undefined)
        if (!progress.isProcessing && progress.currentStep === progress.stepsCount)
            dispatch(updateAllData());

    

    return (loading ? <LoaderComponent description={localization.toLanguageString(LOADER)} /> :
        <>
            <div className="dashboard-all-info">
                {dataExist ?
                    <GridLayout>
                        <GridLayoutItem row={1} col={1}>
                            <InfoPanel />
                        </GridLayoutItem>
                        <GridLayoutItem row={2} col={1}>
                            <PieCharts />
                        </GridLayoutItem>
                    </GridLayout> :
                    <div className="dashboard-no-data-load">
                        <div style={{ margin: "auto" }}>
                            <p className="dashboard-no-data-text">{localization.toLanguageString(DASHBOARD_NO_DATA_TEXT)}</p>
                            <div style={{ float: "left" }}>
                                {GenerateComponent}
                            </div>
                            <div style={{ float: "right" }}>
                                {UploadButton}
                                <DialogModal />
                            </div>
                            <div>
                                {progress && <ProgressComponent progress={progress} error={error} />}
                            </div>
                        </div>
                    </div>
                }
            </div>
            <NotificationGroup style={groupStyle}>
                {isVisibleLinksInfo && <LinksInfo />}
            </NotificationGroup>
        </>
    );
}

export default DashboardD;