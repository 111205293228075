import { isMobile } from 'react-device-detect';

import PayoutsD from "./PayoutsD";
import PayoutsM from "./PayoutsM";

const Payouts = () => {
    return (
        isMobile ? <PayoutsM /> : <PayoutsD />
    );
}

export default Payouts;