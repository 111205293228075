import { Label } from "@progress/kendo-react-labels";
import { isMobile } from 'react-device-detect';

import EnumField from "./EnumField"
import InputField from "./InputField";
import CollectionField from "./CollectionField";
import CheckboxField from "./CheckboxField";
import DateTimeField from "./DateTimeField";


const Field = (props) => {
    let { header, ...fieldProps } = props;
    let className = `${props.orientation === "horizontal" ? "" : "k-mt-3"}`

    if (isMobile && props.ignoreMobile === undefined)
        className = "k-mt-1";
    
    let field = null;
    if (props.collection) {
        field = <CollectionField {...fieldProps} />
    } else if (props.isEnum) {
        field = <EnumField {...fieldProps} />
    } else if (props.propertyType === "System.Boolean" ){
        field = <CheckboxField {...fieldProps} />
    } else if (props.propertyType === "System.DateTime") {
        field = <DateTimeField {...fieldProps} />
    } else {
        field = <InputField {...fieldProps} />
    }
    if (isMobile && header === "Период учета прибыли")
        header = "Период";
    if (isMobile && header === "Метод расчетов")
        header = "Метод";

    return (
        <div className="col">
            <Label className={className}>{header}</Label>
            {field}
        </div>
    )
}

export default  Field;