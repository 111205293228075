import { useState } from "react";
import { useSelector } from "react-redux";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import PortfolioTable from "./PortfolioTable"


const PortfolioTabs = (props) => {
    const data = useSelector(state => state.portfolio.data);
    const [selected, setSelected] = useState(0);

    const handleSelect = (e) => {
        setSelected(e.selected);
    };

    return (
        <TabStrip selected={selected} onSelect={handleSelect} className="k-mt-3">
            {data.map(x => {
                return (
                    <TabStripTab title={x.name} key={x.name} >
                        <PortfolioTable data={x}/>
                    </TabStripTab>
                )
            })}
        </TabStrip>
    )
}

export default PortfolioTabs;