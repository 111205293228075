import { isMobile } from 'react-device-detect';

import RegisterD from "./RegisterD";
import RegisterM from "./RegisterM";

const Register = () => {
    return (
        isMobile ? <RegisterM /> : <RegisterD />
    );
}

export default Register;