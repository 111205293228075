export const PlPeriod = "TFI.Define.Modules.Portfolio.PlPeriod";
export const PlMethod = "TFI.Define.Modules.Base.PlMethod";

export const FundMovementType = "TFI.Define.Domain.Brokerage.FundMovementType";
export const TradeAction = "TFI.Define.Domain.Brokerage.TradeAction";
export const Broker = "TFI.Define.Domain.Brokerage.Broker";

export const ConnectionStatus = "TFI.Define.Services.BrokerConnector.ConnectionStatus";

export const EquityDisplayMode = "TFI.Define.Modules.Equity.Enums.EquityDisplayMode";
export const DisplayUnit = "TFI.Define.Modules.Equity.Enums.DisplayUnit";
export const PayoutsTimeFilter = "TFI.Define.Modules.Payouts.PayoutsTimeFilter";
export const TimeInterval = "TFI.Define.Modules.Portfolio.TimeInterval";

export const EnumTypes = [
    PlPeriod,
    PlMethod,
    FundMovementType,
    TradeAction,
    Broker,
    ConnectionStatus,
    EquityDisplayMode,
    DisplayUnit,
    PayoutsTimeFilter,
    TimeInterval,
];