
const DescriptionPanelMobile = ({ number, broker, holder }) => {
    return <div className="border-description-account">

        <div style={{ float: "left" }}>{number}</div>
        <div style={{ float: "right" }}>{broker}</div>
        <div style={{ clear: "left", clear: "right" }}></div>
        <div>{holder}</div>
    </div>
}

export default DescriptionPanelMobile;