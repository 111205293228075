import { useSelector } from "react-redux";

import { ChartTooltip, Sparkline, ChartArea, ChartSeries, ChartSeriesItem } from "@progress/kendo-react-charts";
import { DropDownList } from "@progress/kendo-react-dropdowns";

import { TradeAction, FundMovementType, Broker, ConnectionStatus } from "../../infrastructure/enums";

const percentFormat = new Intl.NumberFormat("en", { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 });
const numericFormat = new Intl.NumberFormat("en", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 });
const numericFormatItalic = new Intl.NumberFormat("en", { style: "decimal", minimumFractionDigits: 1 });
const quantityFormat = new Intl.NumberFormat("en", { style: "decimal", maximumFractionDigits: 2 });
const numStyle = { textAlign: 'right' };
const numStyleItalics = { textAlign: 'right', fontStyle: 'italic' };

const getValue = ({ dataItem, field }) => {
    if (!field) {
        return null;
    }
    const properties = field.split(".");
    let value = dataItem;
    for (let property of properties) {
        if (value === null)
            break;
        value = value[property];
    }
    return value;
}

export const DateTimeCellType = {
    DATE: "DATE",
    TIME: "TIME"
}

export const ConnectionStatusCell = (props) => <EnumCell {...props} type={ConnectionStatus} />
export const BrokerCell = (props) => <EnumCell {...props} type={Broker} />
export const PayoutTypeCell = (props) => <EnumCell {...props} type={FundMovementType} />
export const TradeActionCell = (props) => <EnumCell {...props} type={TradeAction} />

const EnumCell = (props) => {
    const data = useSelector(state => state.enums[props.type])
    const key = getValue(props);
    const value = data?.find(x => x.key === key)?.display
    return <td>{value}</td>;
}

export const DateTimeCell = (props) => {
    const value = getValue(props).split("T");

    const date = value[0];
    const time = value[1].split(".")[0];

    switch (props.type) {
        case DateTimeCellType.DATE:
            return <td>{date}</td>;
        case DateTimeCellType.TIME:
            return <td>{time}</td>;
        default:
            return <td>{[date, time].join(" ")}</td>;
    }
}

export const QuantityCell = (props) => <td style={numStyle}>{quantityFormat.format(getValue(props) ?? 0)}</td>;

export const NumberCell = (props) => {
    const value = getValue(props) ?? 0
    let color = "black";
    if (props.colored) {
        if (value < 0) {
            color = "red";
        } else if (value > 0) {
            color = "green";
        }
    }

    if (props.field === "lastPriceDecorator.Money") {
        if (props.dataItem.lastPriceDecorator.PriceChangeType === "LastClosePrice")
            color = "#D3D3D3";
        else if (props.dataItem.lastPriceDecorator.PriceChangeType === "AbovePreviousPrice")
            color = "#32CD32";
        else if (props.dataItem.lastPriceDecorator.PriceChangeType === "BelowPreviousPrice")
            color = "#FF0000";
        else if (props.dataItem.lastPriceDecorator.PriceChangeType === "QuoteMidPrice")
            color = "#FF8C00";
    }
    let style = props.isItalic ? numStyleItalics : numStyle;
    return (
        <td style={{ ...style, color, ...props.style }} onClick={() => props.onClick && props.onClick(value)} >
            {props.isItalic ? numericFormatItalic.format(value) : numericFormat.format(value)}
        </td>
    );
};

export const ClickableCell = (props) => {
    const value = getValue(props) ?? 0
    return (
        <td className={props.className} onClick={props.onClick} >
            {value}
        </td>
    );
}

export const PercentCell = (props) => {
    let color = "black";
    const value = getValue(props) ?? 0
    
    if (props.colored) {
        if (value < 0) {
            color = "red";
        } else if (value > 0) {
            color = "green";
        }
    }
    return <td style={{ ...numStyle, color }}>{percentFormat.format(getValue(props) ?? 0)}</td>;
}

export const RightAlignCell = (props) => <td onClick={props.onClick || (() => {})}style={{ ...numStyle, ...props.style }}>{getValue(props)}</td>;

export const ToolTipedCell = (props) => (
    <td
        style={{ ...numStyle, ...props.style }}
        className={props.className}
        title={getValue({ dataItem: props.dataItem, field: props.tooltip })}
        onClick={() => props.onClick && props.onClick()}
    >
        {getValue(props)}
    </td>
);

export const DropDownCell = (props) => {

    const handleChange = (e) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value.value,
            });
        }
    };

    const value = getValue(props) || ""

    return (
        <td>
            {props.dataItem.inEdit ? (
                <DropDownList
                    style={{ width: props.width || "100px" }}
                    onChange={handleChange}
                    value={props.data.find(c => c.value === value)}
                    data={props.data}
                    textField="text"
                />
            ) : (
                value.toString()
            )}
        </td>
    );
};

export const SparkLineCell = (props) => {
    const series = getValue(props);
    const positiveSeries = [];
    const negativeSeries = [];
    const zeroSeries = []

    for (let value of series) {
        zeroSeries.push(0)
        if (value === 0) {
            negativeSeries.push(0);
            positiveSeries.push(0);
        } else if (value < 0) {
            negativeSeries.push(value);
            positiveSeries.push(0);
        } else {
            positiveSeries.push(value);
            negativeSeries.push(0);
        }
    }
    const renderTooltip = ({ points }) => {
        const point1 = points[0].value;
        const point2 = points[1].value;
        const { value, color } = point1 === 0 ? { value: point2, color: "green" } : { value: point1, color: "red" };
        return <span style={{ color }}>{numericFormat.format(value)}</span>
    };

    return (
        <td width={props.width}>
            <Sparkline  >
                <ChartArea width={props.width * 0.90} />
                <ChartSeries>
                    <ChartSeriesItem
                        type="area"
                        data={negativeSeries}
                        color="red"
                    />
                    <ChartSeriesItem
                        type="area"
                        data={positiveSeries}
                        color="green"
                    />
                    <ChartSeriesItem
                        type="area"
                        data={zeroSeries}
                        color="white"
                    />
                </ChartSeries>
                <ChartTooltip render={renderTooltip} />
            </Sparkline>
        </td>)
}