import { fork, all, takeEvery } from 'redux-saga/effects';
import { PAYOUTS_LOAD_DATA, PAYOUTS_PROPERTIES_UPDATE } from "../../reducers/payoutsReducer/actions";
import { payoutsLoadSuccess, payoutsLoadError } from "../../reducers/payoutsReducer/creators";
import { requset } from "../infrastructure";

const baseUrl = "/api/payouts";
const propertyUrl = `${baseUrl}/property`

// WORKER SAGAS
function* load() {
    yield requset(baseUrl, "GET", payoutsLoadSuccess, payoutsLoadError);
}
function* update({ payload }) {
    yield requset(propertyUrl, "PUT", payoutsLoadSuccess, null, payload);
}

// WATCHER SAGAS
function* loadPayoutsSaga() {
    yield takeEvery(PAYOUTS_LOAD_DATA, load);
}
function* propertiesUpdateSaga() {
    yield takeEvery(PAYOUTS_PROPERTIES_UPDATE, update);
}

// ROOT SAGA
export default function* payoutsSaga() {
    yield all([
        fork(loadPayoutsSaga),
        fork(propertiesUpdateSaga)
    ]);
}