import { useSelector } from "react-redux";
import { DropDownList } from "@progress/kendo-react-dropdowns";

const EnumField = (props) => {
    const data = useSelector(state => state.enums[props.propertyType]);
    const display = data?.find(x => x.key === props.value).display;
    const value = { key: props.value, display };
    
    const handleChange = (event) => {
        props.onPropertyEdit(props.propertyName, event.target.value.key);
    }
    
    const style = {
        width: props.width
    }

    return (
        <DropDownList
            style={style}
            disabled={props.disabled}
            onChange={handleChange}
            textField="display"
            dataItemKey="key"
            data={data}
            value={value}
        />
    )
}

export default EnumField;