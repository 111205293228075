import { useState } from "react";

import { useLocalization } from "@progress/kendo-react-intl";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";

import { EQUITY_TABLE_HEADER_INSTRUMENTS, EQUITY_TABLE_HEADER_MONEY, EQUITY_TABLE_HEADER_PERIODS } from "../../../../infrastructure/localizationKeys";

import MoneyTable from "./MoneyTab"
import InstrumentsTable from "./InstrumentTab";
import WeeksTab from "./WeeksTab";

import { PeriodTypes } from "../../../../infrastructure/enums/periodTypes";

const CustomPeriodTab = (props) => {
    const localization = useLocalization();
    const [selected, setSelected] = useState(0);

    const handleSelect = (e) => {
        setSelected(e.selected);
    };

    const start = new Date(props.data.start);
    const end = new Date(props.data.end);
    const dayDisplayed = (end - start) / 86400000 + 1;
    const type = props.data.periodType;
    const showWeekTab = type === PeriodTypes.Month || (type === PeriodTypes.Custom && dayDisplayed >= 7)

    let tabHeight = (props.height.replace("px", '') - 81) + "px";

    return (
        <TabStrip selected={selected} onSelect={handleSelect}>

            {showWeekTab && (
                <TabStripTab title={localization.toLanguageString(EQUITY_TABLE_HEADER_PERIODS)}>
                    <WeeksTab data={props.data.weeks} height={tabHeight} weekend={props.data.weekendsContainsData}/>
                </TabStripTab>
            )}

            <TabStripTab title={localization.toLanguageString(EQUITY_TABLE_HEADER_INSTRUMENTS)}>
                <InstrumentsTable height={tabHeight} data={props.data.instruments} start={start} end={end}/>
            </TabStripTab>

            <TabStripTab title={localization.toLanguageString(EQUITY_TABLE_HEADER_MONEY)}>
                <MoneyTable height={tabHeight} data={props.data.money}/>
            </TabStripTab>

        </TabStrip>
    )
}

export default CustomPeriodTab;