import { useDispatch, useSelector } from "react-redux";
import { useLocalization } from "@progress/kendo-react-intl";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { ToolTipedCell, ClickableCell } from "../../../views/DataGridCells";
import { DATE_PARTS_YEAR, TOTAL } from "../../../../infrastructure/localizationKeys";
import { getPositiveNegativeColor, getBlackWhiteColor } from "../../../../infrastructure/gradientPercentToColor";
import { PeriodTypes } from "../../../../infrastructure/enums/periodTypes";
import { equityAddPeriod } from "../../../../store/reducers/equityReducer/creators";
import { dateToString } from "../../../../infrastructure/extensions";

const columns = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
    "total",
]
const uperCaseFirst = (str) => str[0].toUpperCase() + str.slice(1);
const getMonthLocalizationKey = (month) => month === "total" ? TOTAL : `Common_Months_${uperCaseFirst(month)}`;
const TooltipContentTemplate = (props) => <pre>{props.title}</pre>;


const PeriodsTab = (props) => {
    const dispatch = useDispatch();
    const localization = useLocalization();
    const periods = useSelector(state => state.equity.periods);

    const handleAddYearPeriod = (year) => {
        const start = dateToString(new Date(year, 0, 1));
        const end = dateToString(new Date(year, 11, 31));
        dispatch(equityAddPeriod(PeriodTypes.YearTotal, start, end));
    }

    const handleAddMonthPeriod = (year, month, value) => {
        if (!value)
            return;

        if (month < 12) {
            const start = dateToString(new Date(year, month, 1));
            const end = dateToString(new Date(year, month + 1, 0));

            dispatch(equityAddPeriod(PeriodTypes.Month, start, end));
        }
    }
    return (
        <Tooltip openDelay={100} anchorElement="target" content={TooltipContentTemplate}>
            <Grid data={periods} style={{ height: props.height }} >
                <Column
                    field="title"
                    title={localization.toLanguageString(DATE_PARTS_YEAR)}
                    cell={props =>
                        <ClickableCell
                            {...props}
                            className="cursor-pointer"
                            onClick={() => handleAddYearPeriod(props.dataItem[props.field])}
                        />
                    }
                />
                {columns.map((x, i) =>
                    <Column
                        key={x}
                        field={`${x}String`}
                        title={localization.toLanguageString(getMonthLocalizationKey(x))}
                        cell={ props => {
                            const offset = props.dataItem[`${x}CellGradientOffset`];
                            const color = getBlackWhiteColor(offset)
                            const background = getPositiveNegativeColor(offset);

                            return (
                                <ToolTipedCell
                                    {...props}
                                    className="cursor-pointer"
                                    style={{ color, background }}
                                    tooltip={`${x}ToolTip`}
                                    onClick={() => handleAddMonthPeriod(props.dataItem["title"], i, props.dataItem[props.field])}
                                />
                            )
                        }}
                    />
                )}
            </Grid>
        </Tooltip>
    )
}

export default PeriodsTab;