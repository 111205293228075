import { ERROR_REDUCER_NEW_ERROR, ERROR_REDUCER_REMOVE_ERROR } from "./actions";
import { LOGOUT } from "../generalReducer/generalActions";

const initial = {
    data: []
}

const errorsReducer = (state=initial, action) => {
    switch (action.type) {
        case ERROR_REDUCER_NEW_ERROR:
            return {
                ...state,
                data: [...state.data, action.payload]
            }
        case ERROR_REDUCER_REMOVE_ERROR:
            return {
                ...state,
                data: state.data.slice(1)
            }
        case LOGOUT:
            return initial;
        default:
            return state;
    }
}

export default errorsReducer;